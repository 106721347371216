import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ReactComponent as PasswordResetSuccess } from '../../../../assets/icons/reset_password_success.svg';
import PasswordResetBackground from '../../../../assets/images/reset_password_background.png';

import { useStyles } from "./styles";

const RecoverySuccess = (props) => {
  const history = useHistory();
  const classes = useStyles({ background: PasswordResetBackground });

  const handleLogin = () => {
    history.push('/login');
  }

  return (
    <Box className={classes.mainContainerStyle}>
      <Box className={classes.mainform}>
        <PasswordResetSuccess />

        <Box>
          <Box>
            <Typography className={classes.pwdMsg} align="center">
              Password was updated successfully!
            </Typography>
            <Typography className={classes.pwdMsg2} align="center">
              Great, now you are able to login and enjoy Pebbles AI.
            </Typography>
          </Box>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleLogin()}
            style={{
              margin: '28px auto 0'
            }}
          >
            Log in now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RecoverySuccess;
