import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const companyAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyIntelAttachments: builder.query<any, any>({
      query: (params) => ({
        url: "/v1/company_intel/attachments",
        method: "get",
        params
      })
    }),
    deleteCompanyIntelData: builder.mutation<any, any>({
      query: (id) => ({
        url: `/v1/company_intel/attachments/${id}`,
        method: "delete"
      })
    }),
    uploadCompanyIntelInformation: builder.mutation<any, any>({
      query: ({ file, type }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/v1/company_intel/attachments?file_type=${type}`,
          method: "post",
          body: formData
        };
      }
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getCompanyIntelAttachments = async (params): Promise<any> => {
  return apiCall(companyAPI.endpoints.getCompanyIntelAttachments, params);
};

export const deleteCompanyIntelData = async (id): Promise<any> => {
  return apiCall(companyAPI.endpoints.deleteCompanyIntelData, id);
};

export const uploadCompanyIntelInformation = async (body): Promise<any> => {
  return apiCall(companyAPI.endpoints.uploadCompanyIntelInformation, body);
};
