import React from "react";
import { Modal, Typography, Box, IconButton } from '@material-ui/core';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { pdfjs } from "react-pdf";

import { useStyles } from "./styles";

interface Props {
  open: boolean;
  document: { 
    uri: string,
    name: string,
    type: string,
  };
  onClose: () => void;
}

// Manually set the worker to avoid dynamic import issues
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;

const DocumentPreviewModal: React.FC<Props> = ({
  open,
  document,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className='MuiDialog-root'
    >
      {open && (
        <Box className={classes.modalBox}>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>
            {document.name}
          </Typography>
          <DocViewer
            className={classes.viewer}
            pluginRenderers={DocViewerRenderers}
            documents={[document]}
          />
        </Box>
      )}
    </Modal>
  );
};

export default DocumentPreviewModal;
