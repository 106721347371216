import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import TextField from '../../../../components/main/CustomFields/TextField';

import { useStyles } from './styles';

interface Props {
  campaigns: any;
  searchTerm: string;
  isSubscriptionActive: boolean;
  isOnboardingCompleted: boolean;
  handleSearchChange: (event: any) => void;
}

const Filters: React.FC<Props> = ({
  campaigns,
  searchTerm,
  isSubscriptionActive,
  isOnboardingCompleted,
  handleSearchChange,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.filters}>
      <Box className={classes.filtersLeftBlock}>
        {/* {campaigns && Object.entries(campaigns).map(([key, value]: [string, any]) => (
          <Box
            style={{
              width: 'fit-content',
              padding: '4px 16px',
              background: value.color,
              borderRadius: '26px',
            }}
          >
            <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: 'white' }}>
              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </Typography>
          </Box>
        ))} */}
      </Box>
      <Box 
        className={classes.filtersRightBlock}
        style={{
          // opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
          // pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all',
        }}
      >
        <TextField
          type={'search'}
          placeholder={'Search campaigns'}
          value={searchTerm}
          showClear
          onChange={handleSearchChange}
          onSearchPress={() => { }}
          onKeyPress={() => { }}
        />
      </Box>
    </Box>
  );
};

export default Filters;
