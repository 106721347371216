import React from "react";

import {
  Grid,
  Box,
  Typography,
  TextareaAutosize,
  TextField,
  createStyles,
  FormHelperText
} from "@material-ui/core";

interface IProps {
  textLabel: string;
  labelCount: number;
  placeholder: string;
  rowsCount: number;
  value: string;
  name: string;
  maxCharacterLenght?: number;
  maxWordsLength?: number;
  width: string;
  error?: string;
  customeLabel?: string;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
}

export default class CustomInput extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Box style={webStyle.inputLabel}>
              {!!this.props.customeLabel && <Typography style={webStyle.offering}>{this.props.customeLabel}</Typography>}
              {this.props.textLabel !== "" && <Typography style={webStyle.offering}>{this.props.textLabel} {this.props.labelCount + 1}</Typography>}
              {this.props.required && <Typography style={webStyle.required}>*</Typography>}
            </Box>
            <TextField
              variant="outlined"
              multiline
              maxRows={12}
              id="outlined-multiline-static"
              placeholder={this.props.placeholder}
              minRows={this.props.rowsCount}
              value={this.props.value}
              InputProps={{
                style: {
                  ...webStyle.inputProps, width: this.props.width,
                  fontStyle: this.props.value ? "normal" : "italic",
                  color: this.props.value ? '#000' : "#94A3B8",
                }
              }}
              style={{ ...webStyle.inputProps1, width: this.props.width, }}
              error={!!this.props.error}
              name={this.props.name}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => this.props.onChange(event, this.props.labelCount)}
            />
            {!!this.props.error && (
              <FormHelperText error>
                {this.props.error}
              </FormHelperText>
            )}
            {this.props.maxWordsLength
              ? <Typography 
                  style={{
                    ...webStyle.characterLimit,
                    color: this.props?.value?.trim()?.split(' ')?.filter(item => item?.trim()?.length)?.length > this.props.maxWordsLength
                      ? 'red' 
                      : '#475569'
                  }}
                >
                  {this.props?.value?.trim()?.split(' ')?.filter(item => item?.trim()?.length)?.length || 0}/{this.props.maxWordsLength} words
                </Typography>
              : this.props.maxCharacterLenght
                ? <Typography 
                    style={{
                      ...webStyle.characterLimit,
                      color: this.props?.value?.length > this.props.maxCharacterLenght 
                        ? 'red' 
                        : '#475569'
                    }}
                  >
                    {this.props?.value?.length || 0}/{this.props.maxCharacterLenght} characters
                  </Typography>
                : null
            }
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const webStyle: any = createStyles({
  inputProps: {
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    padding: '10px',
  },
  inputProps1: {
    // padding: '10px',
  },
  characterLimit: {
    textAlign: 'end' as 'end',
    fontSize: '0.75rem',
    fontWeight: 500,
    color: '#475569',
    paddingTop: '5px'
  },
  offering: {
    fontSize: '0.87rem',
    fontWeight: 500,
    paddingLeft: "12px"
  },
  inputLabel: {
    height: 'fit-content',
    minHeight: '24px',
    display: 'flex',
  },
  required: {

  },
});
