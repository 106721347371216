import React, { useState } from "react";
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import ConfirmationModal from "../../../../components/main/ConfirmationModal";
import { enqueueSnackbar } from "notistack";
import { deleteChat } from "../../../../redux/services/copilot";

import { useStyles } from "./styles";

const SideBar = ({
  open,
  chats,
  chat,
  loading,
  isOnboardingCompleted,
  isSubscriptionActive,
  handleGetChats,
  setSideBarOpen,
  setChat,
}) => {
  const [deleteConfig, setDeleteConfig] = useState<{ active: boolean, targetId: number | string | null }>({
    active: false,
    targetId: null
  });

  const history = useHistory();
  const classes = useStyles();

  const handleDeleteChat = async (id) => {
    const { error } = await deleteChat(id);

    if (!error) {
      if (chat?.id === id) {
        if (history.location.pathname === '/copilot') {
          setChat(null);
          handleGetChats();
        } else {
          history.push(`/copilot`);
        }
      } else {
        handleGetChats(false);
      }

      enqueueSnackbar(
        'Chat deleted successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to delete the chat',
        { variant: 'error' }
      )
    }

    setDeleteConfig({
      active: false,
      targetId: null
    })
  }

  return (
    <>
      <Box className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}>
        <Box className={classes.sideBarCloseButton}>
          <IconButton onClick={() => setSideBarOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          className={classes.sideBarContent}
          style={{
            opacity: (isOnboardingCompleted && isSubscriptionActive) ? '1' : '0.5',
            pointerEvents: (isOnboardingCompleted && isSubscriptionActive) ? 'all' : 'none',
          }}
        >
          {open && (
            loading
              ? <CircularProgress
                  color="inherit"
                  style={{ margin: '60px auto' }}
                />
              : chats?.map((item, index) => (
                  <Box
                    key={index + item.id}
                    className={`${classes.sideBarItem} ${(chat?.id === item.id || item.id === 'none') ? classes.sideBarItemActive : ''}`}
                    onClick={() => {
                      if (item.id && item.id !== 'none') {
                        setSideBarOpen(false);
                        history.push(`/chat/${item.id}`);
                      }
                    }}
                  >
                    <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
                      {item.title}
                    </Typography>
                    {item.id !== 'none' && (
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                        
                          setDeleteConfig({
                            active: true,
                            targetId: item.id
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                ))
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          // className={classes.newChatButton}
          onClick={() => {
            if (history.location.pathname === '/copilot') {
              setChat(null);
              handleGetChats();
            } else {
              history.push('/copilot')
            }
          }}
          style={{
            opacity: (isOnboardingCompleted && isSubscriptionActive) ? '1' : '0.5',
            pointerEvents: (isOnboardingCompleted && isSubscriptionActive) ? 'all' : 'none',
          }}
          startIcon={<>+</>}
        >
          New chat
        </Button>
      </Box>

      <ConfirmationModal
        open={deleteConfig.active}
        title={'Are you sure you want to delete the chat?'}
        description={'Please be aware that the entire conversation with Pebbles will be permanently deleted and cannot be recovered.'}
        confirmBtnText={'Delete'}
        rejectBtnText={'Cancel'}
        onClose={() => setDeleteConfig({
          active: false,
          targetId: null
        })}
        onConfirm={() => handleDeleteChat(deleteConfig.targetId)}
        onReject={() => setDeleteConfig({
          active: false,
          targetId: null
        })}
      />
    </>
  );
};

export default SideBar;
