import { Box, Button, Typography } from "@material-ui/core";
import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import React from "react";
import { landingBg, landingBg2, landingInner } from "./assets";
import { ReactComponent as EditWhiteIcon } from '../../../assets/icons/edit_white.svg';

export interface Props extends WithStyles<any> {
  history?: any;
  showCompleteOnboardingBaner?: boolean;
  showRenewLicense?: boolean,
  showActivateLicense?: boolean;
}

const styles = () =>
  createStyles({
    container: {
      height: 235,
      // backgroundImage: `url(${landingBg})`,
      backgroundImage: `url(${landingBg2})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "100%",
      overflow: "hidden",

      "@media (min-width: 768px)": {
        borderRadius: "0px 0px 20px 20px"
      }
    },

    inner: {
      width: 'fit-content',
      height: "100%",
      padding: "26px 20px",
      display: "flex",
      flexDirection: "column",
      background: '#00000099',
      boxSizing: "border-box",
      backdropFilter: 'blur(15px)',
    },

    subHeading: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: "11px",
      color: "#FFFFFF",
      marginTop: 26,
      letterSpacing: '1px'
    },

    heading: {
      fontWeight: 600,
      fontSize: 19,
      lineHeight: "21px",
      color: "#FFFFFF",
      marginTop: 9
    },

    description: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: "13px",
      color: "#FFFFFF",
      marginTop: "auto",
      letterSpacing: "0em"
    },
    fillOnboarding: {
      width: 'fit-content',
      height: 'fit-content',
      marginTop: 'auto',
      background: 'transparent !important',
      padding: '7px 16px 7px 21px',
      '& .MuiButton-label': {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '16px'
      }
    }
  });

export class LandingHead extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Box className={classes.container}>
          {(this.props.showActivateLicense || this.props.showRenewLicense)
            ? <Box
                className={classes.inner}
                style={{ padding: '60px 20px' }}
              >
                <Typography 
                  className={classes.heading}
                  style={{ margin: '0px', fontSize: '32px', fontWeight: '600', lineHeight: '35px', color: '#FFFFFF' }}
                >
                  {this.props.showRenewLicense
                    ? 'Renew your license'
                    : 'Activate your license'
                  }
                </Typography>
                <Typography
                  className={classes.description}
                  style={{ fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#E2E8F0' }}
                >
                  Unlock the full potential of Pebbles AI platform.
                </Typography>
                <br />
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  className={classes.fillOnboarding}
                  style={{ padding: '7px 16px' }}
                  onClick={() => this.props.history.push('/subscriptions')}
                >
                  Go to subscriptions
                </Button>
              </Box>
            : this.props.showCompleteOnboardingBaner
              ? <Box className={classes.inner}>
                  <Typography 
                    className={classes.heading}
                    // style={{ margin: '0px', fontSize: '24px', fontWeight: '600', lineHeight: '35px', color: '#FFFFFF' }}
                  >
                    Fill in onboarding to start <br />
                    using Pebbles Ai
                  </Typography>
                  <Typography
                    className={classes.description}
                    style={{ marginTop: '20px' }}
                    // style={{ fontSize: '10px', fontWeight: '500', lineHeight: '15px', color: '#E2E8F0' }}
                  >
                    Without onboarding, you won't be able to use the platform, <br />
                    as we want to ensure the best results for you.
                  </Typography>
                  <br />
                  <Button
                    color={'primary'}
                    variant={'outlined'}
                    className={classes.fillOnboarding}
                    startIcon={<EditWhiteIcon />}
                    onClick={() => this.props.history.push('/onboarding')}
                  >
                    Fill in onboarding
                  </Button>
                </Box>
              : <Box 
                  className={classes.inner}
                  style={{ width: '235px' }}
                >
                  <Typography className={classes.subHeading}>
                    IMPORTANT UPDATE
                  </Typography>

                  <Typography className={classes.heading} component="h1">
                    Welcome to Pebbles Ai
                  </Typography>

                  <Typography className={classes.description}>
                    Congratulations, you are one of our 1,000 users. Stay tuned for
                    early access to new features.
                  </Typography>
                </Box>
          }
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(LandingHead);
