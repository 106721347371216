import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  metadata: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.metadata = action.payload;  // Direct mutation
    },
    updateProfile: (state, action) => {
      state.metadata = {
        ...state.metadata,  // Merge existing profile with updates
        ...action.payload,
      };
    },
    removeProfile: (state) => {
      state.metadata = null;  // Clear profile data
    },
  },
});

export const { 
  setProfile, 
  updateProfile, 
  removeProfile 
} = profileSlice.actions;

export default profileSlice.reducer;