import React from "react";
import { Box, Container, IconButton, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import LandingOnDemandIntelligence from "./LandingOnDemandIntelligence.web";
import LandingController, { Props } from "./LandingPageController.web";
import LandingSalesAssets from "./LandingSalesAssets.web";
import LandingTaticalOutReach from "./LandingTaticalOutReach.web";
import LandingWorkFlow from "./LandingLibraryAndWorkflow.web";
import LandingHead from "./LandingHead.web";
import ConfirmationModal from "../../../components/main/ConfirmationModal";
import { removeUrlParams } from "../../../helpers/other";

const styles = () =>
  createStyles({
    heading: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: "57.6px",
      marginTop: 40,

      "@media (max-width: 767.98px)": {
        fontSize: 28,
        lineHeight: "32px",
      },
    },

    block: {
      marginTop: 20,
    },
  });

export class LandingPage extends LandingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Container
          style={{
            maxWidth: '100%'
          }}
        >
          <div style={webStyle.headerContainer}>
            <LandingHead
              history={this.props.history}
              showActivateLicense={this.state.showActivateLicense}
              showRenewLicense={this.state.showRenewLicense}
              showCompleteOnboardingBaner={this.state.showCompleteOnboardingBaner}
            />
            {/* {!this.state.rightMenu && (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-testid="right-menu"
                onClick={this.handleRightMenu}
                style={webStyle.rightBar}
              >
                <img src={rightBar} alt="right-bar" />
              </IconButton>
            )} */}
          </div>

          <Box
            style={{ 
              opacity: (this.state.showCompleteOnboardingBaner || this.state.showActivateLicense) ? 0.5 : 1,
              pointerEvents: (this.state.showCompleteOnboardingBaner || this.state.showActivateLicense) ? 'none' : 'all'
            }}
          >
            <Typography className={classes.heading}>
              Dashboard
            </Typography>

            <Box className={classes.block}>
              <LandingOnDemandIntelligence
                data-testid="on-demand-intelligence"
                onClick={() => this.handleRedirectChatHistory()}
              />
            </Box>

            <Box className={classes.block}>
              <LandingTaticalOutReach
                onClick={(tab: number) =>
                  this.handleRedirectTactical(tab)
                }
              />
            </Box>

            <Box className={classes.block}>
              <LandingSalesAssets />
            </Box>

            <Box className={classes.block}>
              <LandingWorkFlow
                data-testid="workflow"
                onClick={this.handleRedirectWorkFlow}
                onClick1={this.handleRedirectLibrary}
                myLibraries={this.state.myLibraries}
                handelColorInMyWork={this.handelColorInMyWork}
                handelColorInMyLibrary={this.handelColorInMyLibrary}
                handleWorkFlowsCreation={this.handleWorkFlowsCreation}
                handleEmailCreationMyLibrary={this.handleEmailCreationMyLibrary}
              />
            </Box>
          </Box>
        </Container>

        <ConfirmationModal
          open={this.state.showCompleteOnboardingBaner && this.state.showCompleteOnboardingModal}
          title={'Fill in onboarding to start using the platform'}
          description={'Without onboarding you cannnot use the platform, because we wanna generate the best results for you.'}
          onClose={() => this.handleCloseCompleteOnboardingModal()}
          rejectBtnText={'Fill in onboarding'}
          onReject={() => {
            removeUrlParams();

            this.props.navigation.navigate('/onboarding');
          }}
        />
      </>
    );
  }
}

const webStyle = {
  headerContainer: {
    display: "flex",
    alignItems: "start",
  },
  rightBar: {
    // position: "absolute" as "absolute",
    // right: "60px",
    zIndex: "9999",
    height: "max-content",
  },
};

export default withStyles(styles)(LandingPage);
