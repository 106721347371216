import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
  },
  appBar: {
    left: 15,
    zIndex: 999999,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {

  },
  menuButton: {
    marginLeft: 7,
    transform: 'translate(0px, 10px)',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 13,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '250px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden'

  },
  drawerOpen: {
    height: '100%',
    width: '265px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#000',
    color: '#fff',
  },
  drawerClose: {
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '72px',
    background: '#000',
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // display:'flex',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    background: 'white'
  },
  companyBox: {
    width: '100%',
    display: 'flex',
    margintTop: '10px'
  },
  companyLogo: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    cursor: 'pointer'
  },
  listIcon: {
    minWidth: '35px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      '& path': {
        fill: '#fff'
      }
    },
    '&:hover': {
      '& svg': {
        '& path': {
          fill: '#A68B3D'
        }
      }
    }
  },
  listGiftIcon: {
    minWidth: '26px',
  },
  dashboardBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    background: 'linear-gradient(81.72deg, #5A4C22 -8.86%, #6E602F 44.84%, #5A4D22 98.55%)',
    borderRadius: '8px',
    margin: '0 8%',
    '&:hover': {
      background: 'linear-gradient(82.51deg, #A68B3D -3.47%, #CAB354 43.23%, #A68B3D 89.94%)',
      color: '#000',
      '& svg': {
        '& rect': {
          fill: '#000'
        }
      }
    },
    // '&:active': {
    //   background: '#000',
    // }
  },
  dashboardBoxOpen: {
    padding: '12px 30px 12px 30px'
  },
  dashboardBoxClose: {
    padding: '12px 22px',
    margin: '0px 0px 0px 8px'
  },
  divider: {
    background: '#475569'
  },
  shareEarn: {
    width: '235px',
    background: 'black',
    border: '1px solid #CAB354',
    color: '#CAB354',
    margin: '0 auto',
    '& svg': {
      '& path': {
        fill: '#CAB354'
      }
    },
    '&:hover': {
      background: 'black',
      color: 'white',
      '& svg': {
        '& path': {
          fill: 'white'
        }
      },
    }
  },
  shareEarnOpen: {
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: '13px 47px',
  },
  shareEarnClose: {
    padding: '0px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      minWidth: '20px',
      minHeight: '20px',
    }
  },
  cardContainer: {
    border: '1px solid #1E293B',
    borderRadius: '10px',
    padding: '8px',
    margin: '0 16px',
    width: 'auto',
    '&:hover': {
      background: 'linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, #15181E, #15181E)'
    }
  },
  inforWrapper: {
    paddingRight: '6px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  promptNumber: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#475569',
    marginLeft: '16px'
  },
  promptNumberLeft: {
    color: '#fff',
    fontWeight: 700,
  },
  promptText: {
    marginLeft: '16px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#475569'
  },
  listContainer: {
    paddingRight: '6px',
    fontSize: '2rem'
  },
  listText: {
    '&:hover': {
      color: '#A68B3D',
      '& svg': {
        '& path': {
          fill: '#A68B3D'
        }
      }
    }
  },
  settingIcon: {
    cursor: 'pointer'
  },
  activeLink: {
    color: '#A68B3D',
    '& svg': {
      '& path': {
        fill: '#A68B3D'
      }
    }
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    background: "#FFFFFF"
  }
}));