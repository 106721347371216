import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: '700px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '20px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  title: {
    width: '100%',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33px',
    textAlign: 'left',
  },
  viewer: {
    width: '100%',
    height: '600px',
    maxHeight: '600px',
    background: 'transparent !important',
    '& #header-bar': {
      display: 'none'
    },
    '& #loading-renderer': {
      height: '200px'
    },
    // '& #pdf-controls': {
    //   display: 'none'
    // },
    '& #pdf-page-wrapper': {
      margin: '0px'
    },
    '& .react-pdf__Page__textContent': {
      display: 'none'
    },
    '& .react-pdf__Page__annotations': {
      display: 'none'
    }
  }
}));
