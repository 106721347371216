import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    maxWidth: '100%',
    padding: '60px'
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '60px'
  },
  uploadContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '20px'
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CBD5E1',
    borderRadius: '80px'
  },
  CompanyIntelButton: {
    width: '100%',
    minHeight: '44px',
    padding: '10px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
    textTransform: 'none',
    border: '1px solid #CBD5E1'
  },
  textCompanyInput: {
    // maxWidth: '490px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid  #94A3B8',
    padding: '8px 14px',
    alignItems: 'center',
    height: '40px'
  },
  listMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px',
    paddingTop: '40px',
    position: 'relative'
  },
  listContainer: {
    padding: '16px 0px',
    alignItems: 'center',
    borderBottom: '1px solid #CBD5E1',
    display: 'flex',
    justifyContent: 'space-between',
    // cursor: 'pointer'
  },
  iconBtn: {
    width: '15px',
    height: '15px',
  },
  iconBtn2: {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  },
  spanText: {
    color: '#475569',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    marginRight: '3px',
    textTransform: 'uppercase'
  },
  imgicon: {
    width: '24px',
    height: '32px',
    marginRight: '7px'
  },
  activeButton: {
    textTransform: 'none',
    padding: '8px 26px',
    alignItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    borderRadius: '80px',
    color: 'black'
  },
  activeAction: {
    textTransform: 'none',
    padding: '8px 26px',
    alignItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    borderRadius: '80px',
    background: '#000 !important',
    color: '#FFF !important'
  },
  viewCricle: {
    width: '3px',
    height: '3px',
    marginRight: '3px'
  },
  viewButton: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#000',
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
    marginTop: '15px'
  },
  fileTags: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: '#22507B',
    padding: '6px 16px',
    background: '#E2E8F0',
    borderRadius: '26px'
  }
}));
