import React from "react";
import { Route } from "react-router-dom";

function Wrapper({ element, history, match, routeMap }) {
  const navigate = (to, params) => {
    let url = to.includes('/') ? to : routeMap[to] ? routeMap[to].path : '/';

    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }

    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");

    history.push(url);
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    history,
    match
  });
}

const WebRoutesGenerator = (routeMap) => {
  return Object.keys(routeMap).map((route, index) => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;

    return (
      <Route
        key={currentRoute.path + index}
        path={currentRoute.path}
        exact={currentRoute.exact}
        render={props => (
          <Wrapper 
            element={<Component />} 
            routeMap={routeMap} 
            {...props}
          />
        )}
      />
    ) 
  });
};

export default WebRoutesGenerator;
