import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  inputFeildWrapper: { marginTop: "30px" },
  errorMsg: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px",
    fontFamily: "Montserrat"
  },
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paperStyle: {
    padding: "20px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
  },
  typographyStyle1: {
    // margin: "10px 0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px"
  },
  typographyStyle2: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  signupform: {
    width: "80%",
    maxWidth: "520px",
    borderRadius: "28px",
    padding: "40px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
    border: "1px solid #CBD5E1"
  },
  inputFeildText: {
    fontWeight: 600
  },

  submitBtn: {
    margin: "25px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      background: "#000"
    }
  },
  link: {
    cursor: "pointer",
    color: "#3A84C9"
  },
  subHeadingtypographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px"
  },
  inputFeilds: {
    margin: "15px 0px"
  },
  pwdIconColor: {
    color: "#94A3B8"
  },
  input: {
    height: "42px",
    marginTop: "4px",
    borderRadius: " 8px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        height: "44px"
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 12px",
        lineHeight: "21px",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1
      },

      "& .MuiInputBase-input": {
        height: "44px"
      }
    }
  },
}));
