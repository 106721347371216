import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    gap: '20px',
    padding: '20px 80px'
  },
  title: {
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 600,
    lineHeight: '57px',
  },
  referralBoxContainer: {
    display: 'flex',
    gap: '20px',
    border: '1px solid #E2E8F0',
    borderRadius: '28px',
    padding: '20px',
    marginTop: '20px',
  },
  referralBox: {
    width: '50%',
    display: 'flex',
    gap: '40px',
    flexDirection: 'column',
    border: '1px solid #3A84C9',
    borderRadius: '28px',
    padding: '20px',
  },
  massReferralBox: {
    width: '50%',
    display: 'flex',
    gap: '40px',
    flexDirection: 'column',
    border: '1px solid #CAB354',
    borderRadius: '28px',
    padding: '20px',
  },
  referralHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  referralProgress: {
    display: 'flex',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#475569',
  },
  referralCount: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '7px',
    color: '#3A84C9',
  },
  massReferralCount: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '7px',
    color: '#CAB354',
  },
  referralDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  referralFriends: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    color: '#3A84C9',
  },
  massReferralTitle: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
  },
  referralText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    color: '#475569',
  },
  linkIconBlue: {
    color: '#3A84C9',
  },
  linkIconGold: {
    color: '#CAB354',
  },
  additionalOffersContainer: {
    display: 'flex',
    gap: '20px',
  },
  inviteFriendsBox: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '6px',
    border: '1px solid #E3E3E3',
    borderRadius: '28px',
    padding: '20px 16px',
  },
  inviteFriendsContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '245px',
  },
  inviteTitle: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29px',
  },
  inviteText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '#475569',
  },
  otherOffersBox: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
    border: '1px solid #E3E3E3',
    borderRadius: '28px',
    padding: '20px 16px',
  },
  otherOffersText: {
    maxWidth: '209px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29px',
    color: '#94A3B8',
  },
}));