import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, IconButton, Radio } from '@material-ui/core';
import { ReactComponent as RadioButtonIcon } from '../../../../assets/icons/radiobutton.svg';
import { ReactComponent as RadioButtonCheckedIcon } from '../../../../assets/icons/radiobutton_checked.svg';

import { useStyles } from './styles';

interface Props {
  name?: string;
  checked: boolean;
  label: string | React.ReactNode;
  error?: string;
  disabled?: boolean;
  onChange: (event: any, value: boolean) => void;
}

const RadioButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <FormControl 
      error={!!props.error} 
      variant="standard"
      className={classes.wrapper}
    >
      <FormControlLabel 
        control={
          <Radio 
            className={classes.radio} 
            checkedIcon={<RadioButtonCheckedIcon />}
            icon={<RadioButtonIcon />}
            disabled={props.disabled}
          />
        }
        className={classes.controll} 
        name={props.name}
        value={props.name}
        checked={props.checked}
        label={props.label}
        onChange={props.onChange}
        {...props}
      />
      {props.error && (
        <FormHelperText error>
          {props.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RadioButton;
