import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const PrivacyPolicy = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Typography style={{ textAlign: 'center', fontSize: '48px', fontWeight: '600', lineHeight: '58px' }}>
          Privacy Policy
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
          Updated 1 Nov 2024
        </Typography>
        <Box className={classes.content}>
          <Typography className={classes.description}>
            At Pebbles.ai we take the issue of your Privacy very seriously. You share your data with us so that we can work with you to put in place the best go to market strategy possible to make your company successful. And that is a privilege and responsibility we take very seriously.
            <br /><br />
            The following Privacy Policy details what personal data we collect as a company. And how, as a company, we deal with it.
            <br /><br />
            Please read the following carefully to understand our views and practices regarding your personal data. And how we will treat it.
            <br /><br />
            For the purposes of data protection legislation, the data controller is GTMSAI Ltd of 82 Wandsworth Bridge Road, London, United Kingdom, SW6 2TF trading as Pebbles.ai
            <br /><br />
            Our Data Protection Officer is Michael Blakeley, and he can be contacted at <a href="mailto:data@trypebbles.ai">data@trypebbles.ai</a>.
          </Typography>
          <Typography className={classes.title}>
            Who we are and what we do
          </Typography>
          <Typography className={classes.description}>
            GTMSAI Limited is your all-in-one AI GTM platform for faster, more profitable growth, perfected for B2B success.
            <br /><br />
            We may also from time to time provide ancillary services around these such as training and support services for our B2B clients.
            <br /><br />
            We collect personal data of the following types of people to allow us to undertake our business:
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                You, our customer, and your employees we interact with.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Your personal and company data which you share with us. <br />
                When you register with the company as a member or to one of our events.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                If you are a private individual or sole trader we may have your personal data.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Supplier contacts to support our services.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Our own Employees, consultants, temporary workers.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Any information you give us about you to carry out our core business and ancillary activities.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            Information you give to us, or we collect about you.
          </Typography>
          <Typography className={classes.description}>
            This is information about you that you give us by filling in forms/ sending emails to us our Pebbles.ai website (our Site). Or by providing information to us for processing (our System) or by corresponding with us by phone, e-mail or otherwise. It includes information you provide when you register to use our site. To enter our database, subscribe to our services, attend our events, or complete a feedback survey, and when you report a problem with our site. It also includes any personal data you may provide to a third party on behalf of whom we process your data for.
            <br /><br />
            Our System relates to our GTMS AI services.
            <br /><br />
            These typically will include information provided by our customers regarding their:
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                Name
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Address
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Company Information
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Email Address
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Personal data of your employees
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Correspondence history
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            This is stored in our systems and will be only used to the extent permitted by you. We may access and use your Customer Data only for the purposes for which you have submitted it to us to (a) provide information to you, (b) make contact with you, (c) provide services to you, or (d) maintain the operations and security of the website and services we provide to you. 
            <br /><br />
            We will not use your personal information for any other purposes, for example the communication of marketing material (where there is no legitimate business interest), unless we have your specific consent to do so.
            <br /><br />
            Information we collect about you when you visit our website.
            <br /><br />
            With regard to each of your visits to our site or system we may collect information such as technical information, including the Internet Protocol (IP) address used to connect your computer to the Internet, your login information if applicable, browser type and version, browser plug-in types and versions, operating system and platform.
            <br /><br />
            Purposes of the processing and the legal basis for the processing
            <br /><br />
            We use information held about you in the following ways:
            <br /><br />
            To carry out our obligations arising from any contracts we intend to enter into or have entered into between you and us and to provide you with the information, products and services that you request from us in terms of GTMS.
            <br /><br />
            The core service we offer to our customers consists of the following:
            <br /><br />
            Our legal basis for the processing of personal data is our legitimate business interests, described in more detail below, although we will also rely on contract, legal obligation and consent for specific uses of data.
          </Typography>
          <Typography className={classes.title}>
            Our Legitimate Business Interests
          </Typography>
          <Typography className={classes.description}>
            Our legitimate interest in collecting and retaining your personal data is described below:
            <br /><br />
            As a B2B GTMS solution, our legitimate interest is for the purposes strictly necessary for provision of this service.
            <br /><br />
            In particular, we rely on our legitimate business interest of supporting you in the roll out of your GTM’s and related services.
            <br /><br />
            This includes retaining such information on an ongoing basis from our interactions and aggregated data to help us.
          </Typography>
          <Typography className={classes.description}>
            Other Uses we will make of your data:
          </Typography>
          <ul 
            className={classes.ul}
            style={{ marginTop: '0px' }}
          >
            <li>
              <Typography className={classes.description}>
                Use of our website;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to notify you about changes to our service;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to ensure that content from our site is presented in the most effective manner for you and for your computer.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            We will use this information:
          </Typography>
          <ul 
            className={classes.ul}
            style={{ marginTop: '0px' }}
          >
            <li>
              <Typography className={classes.description}>
                To administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to improve our site to ensure that content is presented in the most effective manner for you and for your computer;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to allow you to participate in interactive features of our service, when you choose to do so;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                as part of our efforts to keep our site safe and secure;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            Aggregating Information
          </Typography>
          <Typography className={classes.description}>
            Pebbles AI may aggregate Information: generally, this will be in relation to GTM’s materials and protocols and not include Personal Data.  As this information will be aggregated and does not personally identify you or any other user.
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                Visitors will be tracked anonymously even before they become contacts. If and when a visitor fills out a form, our technology will associate their previous page views based on the tracking cookie.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                If a visitor deletes his or her cookies, he or she will be considered a new visitor and will be assigned a new cookie. However, our technology will automatically deduplicate form submissions coming from the same email address, even if different browser cookies were associated with the submissions.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            Contact forms and email links <br />
            Should you choose to contact us using the contact form on our Contact Us page, the data that you supply will be forwarded to us and into Hubspot who provide us with marketing automation services and we consider Hubspot to be third party data processors. 
            <br /><br />
            Hubspot also provide us with CRM services and we consider Hubspot to be a third party processor in this regard also.  The contact details you submit will not be stored on the mesh-ai.com website database.
            <br /><br />
            Should you choose to contact us via email, the data you supply will be processed in Google Mail and Google Drive.
          </Typography>
          <Typography className={classes.title}>
            Email newsletter
          </Typography>
          <Typography className={classes.description}>
            If you choose to join our email newsletter, the email address that you submit to us will be forwarded to Hubspot. 
            <br /><br />
            You can subscribe to the email newsletter through an online automated process should you wish to do so but you do so at your own discretion. Some subscriptions may be manually processed through prior written agreement with the user. Subscriptions are taken in compliance with UK Spam Laws detailed in the Privacy and Electronic Communications Regulations 2003.
            <br /><br />
            Email marketing campaigns published by this website or its owners may contain tracking facilities within the actual email content. Subscriber activity is tracked and stored in Hubspot for future analysis and evaluation. Such tracked activity may include; the opening of emails, forwarding of emails, the clicking of links within the email content, times, dates and frequency of activity [this is by no means a comprehensive list]. This information is used to refine future email campaigns and supply the user with more relevant content based around their activity.
            <br /><br />
            Your email address will remain within Hubspot’s database for up to 3 years from the date of your consent, or for as long as we continue to use Hubspot’s services for email marketing or until you specifically request removal from the list. In compliance with UK Spam Laws and the Privacy and Electronic Communications Regulations 2003, subscribers can do this by unsubscribing using the unsubscribe links contained in any email newsletters that we send you or by requesting removal via email. When requesting removal via email, please send your email to us using the email account that is subscribed to the mailing list.
            <br /><br />
            Should you decide to opt out of any further communication, you have the option to delete your profile data from our system entirely at any time by requesting to be unsubscribed and removed.
          </Typography>
          <Typography className={classes.title}>
            Cookies
          </Typography>
          <Typography className={classes.description}>
            Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. For detailed information on the cookies we use and the purposes for which we use them see our Cookie notice.
          </Typography>
          <Typography className={classes.title}>
            Disclosure of your information inside and outside of the EEA
          </Typography>
          <Typography className={classes.description}>
            Information may be accessed by users of the Pebbles.ai Systems or shared information who have travelled or reside outside of the EEA, however we do currently envisage making any
            <br /><br />
            As a global service we may also transfer data outside of the EEA to service providers located in such areas, in such circumstances we will seek to ensure that appropriate safeguards are put in place, and perform the necessary due diligence.
          </Typography>
          <Typography className={classes.description}>
            Selected third parties may include:
          </Typography>
          <ul 
            className={classes.ul}
            style={{ marginTop: '0px' }}
          >
            <li>
              <Typography className={classes.description}>
                Other users of the Pebbles.ai System for supporting companies, organising related promotional events and activities;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Clients, business partners, suppliers and sub-contractors for the performance and compliance obligations of any contract we enter into with them or you;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                subcontractors including email marketing specialists, event organisers, payment and other financial service providers;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                analytics and search engine providers that assist us in the improvement and optimisation of our site.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            There are certain third parties we work with to help us to delivery to you the best service we can. In order to achieve this, we use a number of third party tools which are carefully chosen to assist us in providing the best service we can, including:
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                Revolut – Our Marketing Partner
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Hubspot – Our Sales CRM and internal marketing tool.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                GCP – We use Google Cloud, a top tier data hosting service, to host our service and keep our data safe. GCP Address: Illia Fitsula europe-west1-b St. Ghislain, Belgium, Europe
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Gsuite - to manage our documents and emails and to keep our data secure.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Github: to host our code and versions of the code.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Like most websites, this site uses Google Analytics (GA) to track user interaction. We use this data to determine the number of people using our site, to better understand how they find and use our web pages and to see their journey through the website.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Business partners, suppliers and sub-contractors for the provision of the Pebbles.ai service to you.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            Analytics and search engine providers that assist us in the improvement and optimisation of our site.
            <br /><br />
            We will disclose your personal information to third parties:
          </Typography>
          <ul 
            className={classes.ul}
            style={{ marginTop: '0px' }}
          >
            <li>
              <Typography className={classes.description}>
                In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                If Pebbles.ai or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, and other agreements; or to protect the rights, property, or safety of Pebbles.ai, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            The lawful basis for the third-party processing will include:
          </Typography>
          <ul 
            className={classes.ul}
            style={{ marginTop: '0px' }}
          >
            <li>
              <Typography className={classes.description}>
                Their own legitimate business interests in processing your personal data, in most cases to prevent fraud;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                satisfaction of their contractual obligations to us as both a data controller and data processor;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                for the purpose of a contract in place or in contemplation;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                to fulfil our legal obligations.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.description}>
            Where we store and process your personal data <br />
            The data that we collect from you will be stored Amazon servers within the EEA however it may be transferred to, and accessed, in destinations outside the European Economic Area (”EEA”).
            <br /><br />
            It may be transferred to third parties outside of the EEA for the purpose of fulfilling our anti-fraud detection and prevention services. It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. This includes staff engaged in, among other things, our anti-fraud services and the provision of support services. By submitting your personal data and that of your users you agree to this transfer, storing or processing. Pebbles.ai LTD will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy notice.
            <br /><br />
            All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology or through bank transfer. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our system, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
            <br /><br />
            Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.
          </Typography>
          <Typography className={classes.title}>
            Retention of your data
          </Typography>
          <Typography className={classes.description}>
            We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests and that you are happy for us to do so. Accordingly, we regularly review our use of data with the aim of removing data that we no longer have a legitimate business interest in maintaining. <br />
            At the end of our contract with you we will delete data in line with our contractual provisions/ as agreed, however please note that any machine learning over processes and techniques will stay with us as part of our IP.
          </Typography>
          <Typography className={classes.title}>
            Your rights
          </Typography>
          <Typography className={classes.description}>
            You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes and we will collect express consent from you if legally required prior to using your personal data for marketing purposes.
            <br /><br />
            The GDPR provides you with the following rights. To:
          </Typography>
          <ul 
            className={classes.ul}
            style={{ marginTop: '0px' }}
          >
            <li>
              <Typography className={classes.description}>
                Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Request erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Request the transfer of your personal information to another party in certain formats, if practicable.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Make a complaint to a supervisory body which in the United Kingdom is the Information Commissioner’s Office.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            Password Restrictions
          </Typography>
          <Typography className={classes.description}>
            At Pebbles.ai Limited we require all passwords to be changed at regular intervals and to be of minimum complexities. We would ask that if you are a user of our system that you keep your password safe and report any loss/breach of your passwords to us as soon as practically possible.
          </Typography>
          <Typography className={classes.title}>
            Access to information
          </Typography>
          <Typography className={classes.description}>
            The Data Protection Act 2018 and the GDPR give you the right to access information held about you. We also encourage you to contact us to ensure your data is accurate and complete. <br />
            Your right of access can be exercised in accordance with the Act (and the GDPR once it is in force).
            <br /><br />
            A subject access request should be submitted to our Data Protection Officer. No fee will apply as laid down by the GDPR.
          </Typography>
          <Typography className={classes.title}>
            Changes to our privacy notice
          </Typography>
          <Typography className={classes.description}>
            Any changes we make to our privacy notice in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy notice.
          </Typography>
          <Typography className={classes.title}>
            Contact
          </Typography>
          <Typography className={classes.description}>
            If you have any questions or require further information, please do not hesitate to contact our Data Protection Officer – <a href="mailto:data@trypebbles.ai">data@trypebbles.ai</a>.
            <br /><br />
            If you are unhappy about our use of your personal information, you can contact us using the details in Section 17 below. You are also entitled to lodge a complaint with the UK Information Commissioner’s Office using any of the below contact methods:
            <br /><br />
            Telephone: <a href="tel:+0303 123 1111">0303 123 1111</a> <br />
            Website: <a href="https://ico.org.uk/concerns">https://ico.org.uk/concerns</a> <br />
            Post: <a href="https://www.google.com/maps/place/Wycliffe+House,+Water+Ln,+Cheshire,+Wilmslow+SK9+5AF,+UK/@53.3255431,-2.2359842,17z/data=!3m1!4b1!4m6!3m5!1s0x487a4d9d76ad39f7:0xcce38b2f3ef45169!8m2!3d53.3255431!4d-2.2359842!16s%2Fg%2F1xpwj9fr?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D">Information Commissioner’s Office Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF</a>
            <br /><br />
            You may prefer to, and are entitled to, lodge a complaint with a different supervisory authority in a country of your choice. A list of European Supervisory Authorities is available here: <a href="https://ec.europa.eu/newsroom/article29/items/612080">https://ec.europa.eu/newsroom</a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
