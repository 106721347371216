import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query<any, any>({
      query: (uuid) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/wallet/account/${uuid}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getWalletBalance: builder.query<any, any>({
      query: (uuid) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/wallet/account/${uuid}/balance`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getTopUps: builder.query<any, any>({
      query: () => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/top-up/pricing`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    createTopUpOrder: builder.query<any, any>({
      query: ({ uuid, body }) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/orders?accountId=${uuid}`,
        method: "post",
        body
      })
    }),
    createCustomTopUp: builder.query<any, any>({
      query: (body) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/top-up/custom`,
        method: "post",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getWallet = async (uuid): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getWallet, uuid);
};

export const getWalletBalance = async (uuid): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getWalletBalance, uuid);
};

export const getTopUps = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getTopUps);
};

export const createTopUpOrder = async (uuid, body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createTopUpOrder, { uuid, body });
};

export const createCustomTopUp = async (body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createCustomTopUp, body);
};