import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1920px',
    height: '100vh',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    padding: '40px',
    margin: '0 auto',
    background: 'linear-gradient(130.05deg, #F9F9F9 -25.99%, #EBE2CF 77.55%)',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  plans: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '32px',
  },
  planCard: {
    width: '460px',
    height: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    background: 'linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    border: '1px solid black',
    overflow: 'hidden',
    position: 'relative'
  },
  benefitTag: {
    padding: '2px 16px 2px 2px',
    background: '#FFFFFF',
    borderRadius: '80px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  benefitsList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    // flexDirection: 'column',
    columnGap: '20px',
    rowGap: '8px',
  }
}));
