import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Chip from "@material-ui/core/Chip";
import { Box, FormHelperText, IconButton, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";

import CustomList from "./CustomList.web";
import CustomInput from "./CustomInput.web";
import CustomButton from "./CustomButton.web";
import ProblemStatement from "../../blocks/email-account-registration/main/ProblemStatement.web";
import { FieldArray } from "formik";
import { modalInfoContent } from "../../blocks/email-account-registration/utils/data"
import { ProblemStatementWithFormik } from "../../blocks/email-account-registration/main/ProblemStatementWithFormik.web";
import OnboardingHelperTooltip from "./OnboardingHelperTooltip";

interface MustHaveProps {
  btnDisplay?: boolean;
  addItem?: any;
  customlistTitle?: string;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
  inputList?: any[];
  textBoxDisplay?: boolean;
  iconName?: string;
  headerName?: string;
  labelsDisplay?: boolean;
  serviceArray?: string[];
  deleteChipByName?: (label: string) => void;
  handleExpand?: () => void;
  mustHaveExpanded?: boolean;
  chipLabels?: string[];
  offeringText?: boolean,
  processText?: boolean,
  inputOfferingList?: any
  goodToHave?: boolean;
  buttonName?: string
  chipError?: string;
  formikTouched?: any;
  onChangeChip?: (value: string[]) => void;
  name?: any;
  formikHandelChange?: any;
  formikValue?: any;
  formikError?: any;
  setFieldValue?: any;
  maxFeilds?: any;
  initData?: boolean
  showBtnFromMustHave?: boolean;
  useFormik?: boolean;
  tooltips?: any;
  closeTooltip?: (section: string, key: string) => void;
  section?: string;
}
// import PrimaryFear from "./PrimaryFear"; //causing build issue thats why removing this

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#D93855",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "120%",
  },
  heading1: {
    color: "#57BE99",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "120%",
  },
  heading2: {
    color: "#A68B3D",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "120%",
  },
  disRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    margin: "10px 5px"
  },
  saveBtn: {
    borderRadius: "8px",
    background: "#000",
    width: "80px",
    height: "44px",
    color: "white",
    "&:hover": {
      backgroundColor: "#000000",
    }
  },
  chipSection: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chipWrapper: {
    margin: "2px",
    border: "1px solid #CBD5E1",
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: '6px 6px 6px 12px',
    color: '#000',
    display: 'flex',
    alignItems: "center",
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere'
  },
  activeChip: {
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere',
    margin: "2px",
    border: "1px solid #000",
    backgroundColor: '#000',
    borderRadius: '16px',
    padding: '6px 12px',
    color: '#fff',
    display: 'flex',
    alignItems: "center",
    cursor: "pointer"
  },
  chipText: {
    fontSize: '14px',
    fontWeight: 500
  },
  addInput: {
    minWidth: '240px',
    '& input': {
      height: '44px !important',
      boxSizing: 'border-box !important',
      backgroungColor: 'transparent !important',
    }
  },
  clearIcon: {
    color: 'black',
    fontSize: '16px',
    fontWeight: 500,
    transform: 'translate(0px, 0.5px)',
    cursor: 'pointer',
  },
  addChipBtn: {
    margin: "2px",
    border: "1px solid #3A84C9",
    backgroundColor: 'white',
    borderRadius: '80px',
    padding: '6px 12px',
    color: '#000',
    display: 'flex',
    alignItems: "center",
    cursor: "pointer"
  },
  addIcon: {
    color: '#3A84C9',
    fontSize: '16px',
    marginRight: '4px',
    fontWeight: 500
  },
  addText: {
    color: '#3A84C9',
  },
  priceCurrencyInput: {
    "& fieldset": {
      border: "none",
      borderLeft: "solid 1px",
      borderColor: "rgba(0,0,0,0.23)",
      borderRadius: 0
    },
    "& #currency": {
      padding: "10px",
    }
  }
}));

function MustHave({
  btnDisplay = false,
  addItem,
  customlistTitle = "",
  handleChange,
  inputList = [],
  textBoxDisplay = false,
  iconName,
  headerName,
  labelsDisplay = true,
  serviceArray = [],
  deleteChipByName,
  handleExpand,
  mustHaveExpanded,
  chipLabels = [],
  offeringText = false,
  processText = false,
  inputOfferingList = null,
  buttonName,
  chipError,
  onChangeChip,
  name,
  formikHandelChange,
  formikValue = [],
  formikError,
  formikTouched,
  setFieldValue,
  maxFeilds = 1,
  initData,
  showBtnFromMustHave = true,
  useFormik = true,
  tooltips,
  closeTooltip = () => {},
  section
}: MustHaveProps) {
  const classes = useStyles();
  const [focusedChip, setFocusedChip] = useState<string>(chipLabels[0]);
  const [openTextBox, setOpenTextBox] = useState<boolean>(false);
  const [chipTextBox, setChipTextBox] = useState<string>("");
  const [error, setError] = useState<string>("");

  const checkChipSelected = (label: string) => {
    return focusedChip === label;
  }

  const handleFocus = (label: string) => {
    setFocusedChip(checkChipSelected(label) ? '' : label);
  };

  const onDelete = (label: string) => {
    const list = chipLabels.filter((key: string) => label !== key);
    onChangeChip?.(list);
    deleteChipByName?.(label);
  }

  const onAddChip = () => {
    if (!chipTextBox) {
      setError('Please enter the designation');
      return;
    };
    if (chipTextBox.trim() === '') {
      setError('This field cannot contain only spaces');
      return;
    }
    if (chipTextBox.length > 300) {
      setError('Max length 300 characters');
      return;
    }
    const numOfWords = chipTextBox.split(" ").length;
    if (numOfWords > 3) {
      setError('Max 3 words for each designation');
      return;
    }

    const existedValue = chipLabels.find((value) => value.toLocaleLowerCase() === chipTextBox.toLocaleLowerCase());

    if (existedValue) {
      handleFocus(chipTextBox)
      setChipTextBox("")
      setOpenTextBox(false)
    } else {
      addItem(chipTextBox)
      onChangeChip([...chipLabels, chipTextBox])
      setChipTextBox("")
      setOpenTextBox(false)
    }
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", margin: ".8rem 0" }}>
        <Accordion
          expanded={mustHaveExpanded}
          onChange={handleExpand}
          style={{ borderRadius: "28px", width: "100%", alignSelf: "center" }}
        >
          <OnboardingHelperTooltip
            open={section && headerName === "MUST HAVE" && tooltips[section].mustHave}
            title={'Helper'}
            description={
              <>The <b style={{ color: '#D93855' }}>MUST HAVE</b> section is required, but completing all fields ensures the gold standard.</>
            }
            placement='left'
            onClose={() => closeTooltip(section, 'mustHave')}
          >
            <AccordionSummary
              style={{ backgroundColor: "black", borderRadius: "8px" }}
              expandIcon={<img src={iconName} alt="Accordion Up" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={headerName === "MUST HAVE" ? classes.heading : headerName === "SHOULD HAVE" ? classes.heading1 : classes.heading2}>{headerName}</Typography>
            </AccordionSummary>
          </OnboardingHelperTooltip>
          <AccordionDetails style={{ flexDirection: "column", padding: "35px" }}>
            <CustomList
              title={customlistTitle}
              servicesList={serviceArray}
              modalContent={modalInfoContent[name as keyof typeof modalInfoContent]}
              headerName={headerName}
            />
            {labelsDisplay && deleteChipByName &&
              <div>
                <div className={classes.chipSection}>
                  {chipLabels.map((label: string, index: any) => (
                    <Box
                      // className={checkChipSelected(label) ? classes.activeChip : classes.chipWrapper}
                      className={classes.chipWrapper}
                      key={label + index}
                      onClick={() => handleFocus(label)}
                    >
                      <Typography className={classes.chipText}>
                        {label}&nbsp;
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(label);
                        }}
                      >
                        <ClearIcon className={classes.clearIcon} />
                      </IconButton>
                    </Box>
                  ))}
                  <Box
                    className={classes.addChipBtn}
                    onClick={() => setOpenTextBox(true)}
                  >
                    <AddIcon className={classes.addIcon} />
                    <Typography className={`${classes.chipText} ${classes.addText}`}>{"Add a keyword"}</Typography>
                  </Box>
                </div>
                {openTextBox && addItem &&
                  <div className={classes.disRow}>
                    <TextField
                      id="my-input"
                      autoFocus
                      value={chipTextBox}
                      className={classes.addInput}
                      variant="outlined"
                      placeholder="Going to market efficacy"
                      onChange={(e: any) => {
                        setChipTextBox(e.target.value);

                        if (e.target.value.length > 300) {
                          setError('Max length 300 characters');
                        } else {
                          const numOfWords = e.target.value.split(" ").length;
                          if (numOfWords > 3) {
                            setError('Max 3 words for each designation')
                          } else {
                            setError('')
                          }
                        }
                      }}
                    />
                    <Button className={classes.saveBtn} onClick={onAddChip}>Save</Button>
                  </div>
                }
                {(!!error || !!chipError) && (
                  <FormHelperText error>
                    {error || chipError}
                  </FormHelperText>
                )}
              </div>
            }
            {textBoxDisplay && handleChange &&
              <FieldArray
                data-test-id="FormikFieldArray"
                name={name}
                render={(arrayHelpers: any) => (
                  <div>
                    {formikValue[name]?.map((field: any, index: any) => (
                      <Box key={index}>
                        <CustomInput
                          width="100%"
                          textLabel={inputList[0].label}
                          labelCount={index}
                          placeholder={inputList[0].placeholder}
                          rowsCount={1}
                          data-testid={"offering-field-" + index}
                          name={`${name}.${index}`}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => formikHandelChange(e, index)}
                          value={formikValue[name][index]}
                          error={formikError[name]?.[index] && formikTouched[name]?.[index] ? formikError[name]?.[index] : ''}
                          maxCharacterLenght={inputList[0].charLength}
                        />
                      </Box>
                    ))}
                    {btnDisplay && (formikValue?.[name]?.length < maxFeilds) && (
                      <CustomButton
                        data-test-id="FormikFieldButton"
                        onClick={() => arrayHelpers.push('')}
                        buttonName={buttonName || "Add a testimonial"}
                      />
                    )}
                  </div>
                )}
              />
            }
            {(offeringText || processText) && inputOfferingList && useFormik && (
              <ProblemStatementWithFormik
                data-test-id={"problemStatementTestId"}
                showListHeader={inputOfferingList.showListHeader}
                navigation={""}
                id=""
                title={""}
                name={inputOfferingList.name}
                offeringText={offeringText}
                inputList={inputOfferingList.inputList}
                serviceArray={[]}
                formikHandelChange={formikHandelChange}
                formikValue={formikValue}
                formikError={formikError}
                touched={formikTouched}
                goodToHave={inputOfferingList?.goodToHave}
                showCustomList={false}
                btnDisplay={btnDisplay}
                buttonName={buttonName}
                maxFeilds={maxFeilds}
                classes={classes}
              />
            )}
            {(offeringText || processText) && inputOfferingList && !useFormik && (
              <ProblemStatement 
                offeringText={offeringText} 
                processText={processText} 
                navigation={""} 
                id="" 
                title={"The Team"} 
                handleChange={inputOfferingList.handleChange} 
                handleChange1={inputOfferingList.handleChange1} 
                handleChange2={inputOfferingList.handleChange2} 
                handleChange3={inputOfferingList.handleChange3} 
                showListHeader={inputOfferingList.showListHeader} 
                inputList={inputOfferingList.inputList} 
                serviceArray={[]} 
                goodToHave={inputOfferingList?.goodToHave} 
              />
            )}
            {btnDisplay && showBtnFromMustHave && (offeringText || processText) && (
              <CustomButton
                data-test-id="FormikFieldButton"
                onClick={addItem}
                buttonName={buttonName || "Add a testimonial"}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default MustHave;
