import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import TextField from "../../../components/main/CustomFields/TextField";

import { useStyles } from "./styles";
import { getProfile } from "../../../redux/services/profile";
import StorageProvider from "../../../framework/main/StorageProvider";
import { getCurrentSubscription } from "../../../redux/services/subscriptions";
import { clearStorages } from "../../../helpers/other";
import { enqueueSnackbar } from "notistack";
import { loginUser, loginUserJava } from "../../../redux/services/authentication";
import { removeProfile, setProfile } from "../../../redux/features/profile";
import { useDispatch } from "react-redux";

const Login = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleSignUp = () => {
    history.push('/signup');
  }

  const handleForgotPassword = () => {
    history.push('/forgot-password');
  }

  const handleLogin = async (email: string, password: string) => {
    setError('');
    setLoading(true);

    const body = {
      email: email,
      password: password,
    };

    const { data: loginRuby, error: loginRubyError } = await loginUser(body);
    const { data: loginJava, error: loginJavaError } = await loginUserJava(body);

    if (loginRuby && !loginRubyError && loginJava && !loginJavaError) {
      await StorageProvider.setItem("user-token", loginRuby.meta.token);
      await StorageProvider.setItem("user-token-java", loginJava.accessToken);
      await StorageProvider.setItem("user-refresh-token", loginJava.refreshToken);

      handleRedirect();
    } else {
      await clearStorages();

      if (loginRubyError?.data?.type === 'email' || loginRubyError?.data?.type === 'password') {
        enqueueSnackbar(
          'Authentication error',
          { variant: 'error' }
        )

        setError('Invalid email or password');
      } else {
        enqueueSnackbar(
          loginRubyError?.data?.error || 'Unexpected error occurred',
          { variant: 'error' }
        )
      }

      setLoading(false);
    }
  }

  const handleRedirect = async () => {
    setLoading(true);

    const { data: profile, error: profileError } = await getProfile(true);
    const { data: subscription, error: subscriptionError } = await getCurrentSubscription();

    if (profile && !profileError) {
      if (!profile.attributes.account.activated) {
        history.push('/verify-signup-code');
      } else if (!profile.attributes.account.full_name?.trim()?.length) {
        history.push('/account-details');
      } else if (!profile.attributes.job_role) {
        history.push('/about-yourself');
      } else if (!subscription && !subscriptionError) {
        history.push('/subscriptions');
      } else {
        history.push('/home');
      }
    } else {
      await clearStorages();

      history.push('/');

      enqueueSnackbar(
        'Failed to get profile data. Try to login again or contact our support',
        { variant: 'error' }
      )
    }
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.layoutContainer}>
        <Box className={classes.emailContainer}>
          <Typography className={classes.sigupText}>
            Welcome back
          </Typography>
          <Typography className={classes.subTitle}>
            Don’t have an account?
            <span
              data-test-id="loginBtn"
              className={classes.loginText}
              onClick={handleSignUp}
            >
              {`Sign up`}
            </span>
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email.")
                .required("Email should not be blank."),
              password: Yup.string()
                .required("Password should not be blank."),
            })}
            onSubmit={(values, formik) => handleLogin(values.email, values.password)}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit
            }) => (
              <Form
                translate={undefined}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  marginTop: '32px'
                }}
              >
                <TextField
                  name={'email'}
                  value={values['email']}
                  placeholder={'Enter the email'}
                  label={'Email'}
                  error={touched['email'] && errors['email']}
                  onChange={(e) => {
                    setFieldValue('email', e.target.value);
                    setError('');
                  }}
                />
                <TextField
                  name={'password'}
                  value={values['password']}
                  placeholder={'Enter the password'}
                  label={'Password'}
                  type={'password'}
                  error={(touched['password'] && errors['password']) || error}
                  onChange={(e) => {
                    setFieldValue('password', e.target.value);
                    setError('');
                  }}
                />
                <Box className={classes.forgotPwdLabelWrapper}>
                  <Typography
                    className={classes.forgotPwdLink}
                    onClick={handleForgotPassword}
                  >
                    Forgot password?
                  </Typography>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{
                    marginTop: '16px'
                  }}
                >
                  Log in
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default Login;
