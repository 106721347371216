import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";


import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/main/StorageProvider";
export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
}

export interface S {
}

export interface SS {
  id: any;
}

export default class ChooseyourOptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  referralCode: string | null = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();

    this.getReferralCode()
  }

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSignUpToMe = async () => {
    await this.saveReferralCode()

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SignUp'
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goForMyCompany = async () => {
    await this.saveReferralCode()

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LetsGetStarted"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  saveReferralCode = async () => {
    if (!this.referralCode) return

    await StorageProvider.setItem("referral_code", this.referralCode);
  }

  getReferralCode = async () => {
    const url = new URL(window.location.href)
    const queryParams = url.searchParams;
    const code = queryParams.get("referral_code") ?? await StorageProvider.getItem("referral_code")
    if (code) { this.referralCode = code }
    let newUrl = `${url.origin}${url.pathname}`
    if (this.referralCode) { newUrl = `${newUrl}?referral_code=${this.referralCode}` }

    window.history.replaceState({ path: newUrl }, "", newUrl);
    await StorageProvider.removeItem("referral_code");
  }
}
