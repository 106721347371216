import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    gap: '8px',
    padding: '40px 0px'
  },
  content: {
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    margin: '32px auto',
  },
  title: {
    marginTop: '20px', 
    fontSize: '16px', 
    fontWeight: 600, 
    lineHeight: '19px'
  }, 
  description: {
    fontSize: '14px', 
    fontWeight: 500, 
    lineHeight: '21px',
    color: '#475569'
  },
  ul: {
    '& li': {
      marginTop: '5px'
    }
  },
}));