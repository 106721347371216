import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '20px',
    background: '#F8FAFC',
    gap: '16px'
  },
  limitCardsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px'
  },
  limitCard: {
    width: '33%',
    padding: '20px',
    border: '1px solid #CBD5E1',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    background: '#FFFFFF'
  },
}));