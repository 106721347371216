import React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";

import LandingList from "./LandingList.web";

export interface Props extends WithStyles<any> {
  onClick: (id?: number | string) => void;
  onClick1: (id?: number | string) => void;
  myLibraries: any;
  handelColorInMyWork?: (emailType?: number) => {};
  handelColorInMyLibrary?: (
    unique_id: number,
    condition1: any,
    condition2: any,
    condition3: any
  ) => {};
  handleWorkFlowsCreation: any;
  handleEmailCreationMyLibrary: any;
}

const styles = () =>
  createStyles({
    container: {
      padding: 20,
      borderRadius: 28,
      backgroundColor: "#F1F5F9"
    }
  });

export class LandingLibraryAndWorkflow extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      onClick1,
      onClick,
      myLibraries,
      handelColorInMyWork,
      handelColorInMyLibrary,
      handleWorkFlowsCreation,
      handleEmailCreationMyLibrary
    } = this.props;

    return (
      <>
        <Paper className={classes.container} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LandingList
                title="MY HISTORY"
                listData={myLibraries}
                onClick={onClick1}
                isLibrary={true}
                handelColorInLiastData={handelColorInMyWork}
                handelColorInLiastData2={handelColorInMyLibrary}
                handleWorkFlowsCreation={handleWorkFlowsCreation}
                handleEmailCreationMyLibrary={handleEmailCreationMyLibrary}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(LandingLibraryAndWorkflow);
