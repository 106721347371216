import React from "react";
import CustomList from "../../../components/main/CustomList.web";
import CustomInput from "../../../components/main/CustomInput.web";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  FormHelperText
} from "@material-ui/core";

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ProblemStatementWithFormikController, {
  Props,
} from "./ProblemStatementWithFormikController.web";
import { styles } from "../utils/styles";
import { modalInfoContent } from "../utils/data";
import { FieldArray } from "formik";
import CustomButton from "../../../components/main/CustomButton.web";

export class ProblemStatementWithFormik extends ProblemStatementWithFormikController {
  constructor(props: Props) {
    super(props);
  }

  renderGoodToHave = (index: number) => {
    const {
      inputList,
      name = "",
      formikHandelChange,
      formikValue,
      formikError,
      touched,
      showListHeader,
      goodToHave = false,
      classes
    } = this.props;

    if (!goodToHave) return null;
    return (
      <Box style={webStyle.boxWrapper}>
        <Typography style={webStyle.title} >{inputList[0].priceTitle}</Typography>
        <div>
          <TextField
            data-testid={"price-field-" + index}
            multiline
            variant="outlined"
            id="outlined-multiline-static"
            minRows={1}
            maxRows={12}
            value={formikValue[name][index]?.price}
            placeholder={inputList[0].pricePlaceholder}
            InputProps={{
              endAdornment: (
                <div style={{ width: '100%' }} className={classes.priceCurrencyInput}>
                  <InputAdornment position="end">
                    <FormControl variant="outlined" style={{ ...webStyle.inputProps1, width: "100%", }}>
                      <Select
                        id="currency"
                        data-testid={"currency-field-" + index}
                        labelId="currency-label"
                        value={formikValue[name][index]?.currency}
                        label="Currency"
                        name={`${name}.${index}.currency`}
                        onChange={(e: any) => formikHandelChange(e, index)}
                        style={{
                          ...webStyle.inputProps1, width: "100%",
                          color: this.styleCondition(formikValue[name][index]?.price, '#000', "#94A3B8"),
                          fontStyle: this.styleCondition(formikValue[name][index]?.price, "normal", "italic"),
                        }}
                      >
                        <MenuItem value="null">Choose</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                      </Select>
                    </FormControl>
                  </InputAdornment>
                </div>
              ),
              style: {
                ...webStyle.inputProps, width: "100%",
                color: this.styleCondition(formikValue[name][index]?.price, '#000', "#94A3B8"),
                fontStyle: this.styleCondition(formikValue[name][index]?.price, "normal", "italic"),
              }
            }}
            style={{ ...webStyle.inputProps1, width: "100%" }}
            name={`${name}.${index}.price`}
            error={formikError[name]?.[index]?.price && !!touched && touched[name]?.[index]?.price ? formikError[name]?.[index]?.price : ""}
            type="number"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement>
            ) => formikHandelChange(e, index)}
          />

        </div>
        {!!(formikError[name]?.[index]?.price) && (
          <FormHelperText error>
            {formikError[name]?.[index]?.price}
          </FormHelperText>
        )}
      </Box>
    )
  }

  render() {
    const {
      inputList,
      serviceArray,
      title,
      btnDisplay = false,
      buttonName,
      name = "",
      formikHandelChange,
      formikValue,
      formikError,
      maxFeilds = 1,
      touched,
      showListHeader,
      goodToHave = false,
      showCustomList = true,
      offeringText = false,
      requiredIndex
    } = this.props;

    return (
      <>
        <Grid container style={this.styleCondition(!!showListHeader, {}, webStyle.mainContainer)}>
          <Grid item xs={12} style={offeringText ? webStyle.servicesContainer2 : { ...webStyle.container, ...(webStyle.servicesContainer) }}>
            {showCustomList && (
              <CustomList
                title={title}
                servicesList={serviceArray}
                modalContent={modalInfoContent[name as keyof typeof modalInfoContent]} 
              />
            )}
            <Box style={webStyle.offeringBox}>
              <FieldArray
                data-test-id="FormikFieldArray"
                name={name}
                render={(arrayHelpers: any) => (
                  <>
                    {formikValue[name]?.map((field: any, index: any) => (
                      <Box key={index} style={{ width: "32%" }}>
                        <CustomInput
                          width="100%"
                          textLabel={inputList[0].offeringTitle}
                          labelCount={index}
                          placeholder={inputList[0].offeringPlaceholder}
                          rowsCount={1}
                          data-testid={"offering-field-" + index}
                          name={`${name}.${index}.title`}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => formikHandelChange(e, index)}
                          value={formikValue[name][index]?.title}
                          error={formikError[name]?.[index]?.title && !!touched && touched[name]?.[index]?.title ? formikError[name]?.[index]?.title : ''}
                          maxCharacterLenght={inputList[0].charLength}
                          maxWordsLength={inputList[0].wordsLength}
                          required={requiredIndex === index}
                        />
                        {this.renderGoodToHave(index)}
                        <CustomInput
                          width="100%"
                          textLabel={inputList[0].descriptionTitle}
                          labelCount={index}
                          placeholder={inputList[0].descriptionPlaceholder}
                          rowsCount={1}
                          data-testid={"description-field-" + index}
                          name={`${name}.${index}.description`}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => formikHandelChange(e, index)}
                          value={formikValue[name][index]?.description}
                          error={formikError[name]?.[index]?.description && !!touched && touched[name]?.[index]?.description ? formikError[name]?.[index]?.description : ""}
                          maxCharacterLenght={inputList[0].charLengthDesc}
                          maxWordsLength={inputList[0].wordsLengthDesc}
                          required={requiredIndex === index}
                        />
                      </Box>
                    ))}
                    {btnDisplay && (maxFeilds === 1 || (formikValue[name]?.length < maxFeilds)) && (
                      <CustomButton
                        data-test-id="FormikFieldButton"
                        onClick={() => {
                          arrayHelpers.push({ title: "", description: "" });
                          arrayHelpers.push({ title: "", description: "" });
                          arrayHelpers.push({ title: "", description: "" });
                        }}
                        buttonName={buttonName}
                      />
                    )}
                  </>
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  mainContainer: {
    // marginTop: "10px",
    margin: '0.8rem 0px',
  },
  container: {
    background: '#fff',
    borderRadius: '28px',
    height: 'max-content',
  },
  servicesMainContainer: {
    margin: '0.8rem 0px',
  },
  servicesContainer2: {
    background: '#fff',
    borderRadius: '28px',
    height: 'max-content',
    padding: '0px 0px',
  },
  servicesContainer: {
    padding: '32px 40px',
  },
  processStyle: {
    flexDirection: 'column' as 'column'
  },
  offeringBox: {
    marginBottom: '2%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as 'wrap'
  },
  inputProps: {
    fontFamily: 'Montserrat',
    borderRadius: '8px',
    fontWeight: 400,
    padding: '10px 0px 10px 10px',
    lineHeight: '21px',
  },
  title: {
    fontWeight: 500,
    fontSize: '0.87rem',
    paddingLeft: "12px"
  },
  inputProps1: {
    // '.MuiInputBase-root': {
    //   padding: '10px 0px 10px 10px !important',
    // }
  },
  boxWrapper: {
    margin: '0.8rem 0px',
  }
}

const styleClass = {
  priceCurrencyInput: {
    // padding: '10px 0px 10px 10px',
    "& fieldset": {
      border: "none",
      borderLeft: "solid 1px",
      borderColor: "rgba(0,0,0,0.23)",
      borderRadius: 0
    },
    "#currency": {
      padding: '0px',
    }
  }
}

export default withStyles(styleClass)(ProblemStatementWithFormik);