import { Box, Grid, Paper, Typography } from "@material-ui/core";
import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import React from "react";

export interface Props extends WithStyles<any> { }
const styles = () =>
  createStyles({
    content: {
      display: "flex",
      alignItems: "center",

      "@media (max-width: 767.98px)": {
        justifyContent: "center",
        marginTop: 40
      },

      "@media (min-width: 768px)": {
        height: "100%"
      }
    },

    text: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "28.8px",
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#94A3B8",

      "@media (min-width: 768px)": {
        marginLeft: "auto",
        marginRight: 78
      }
    },

    outerPaper: {
      padding: 16,
      borderRadius: "28px",
      backgroundColor: "#F1F5F9",

      "@media (min-width: 768px)": {
        padding: 20
      }
    },

    leftBlock: {
      display: "flex"
    },

    leftBlockTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "21.6px",
      letterSpacing: "-0.005em",
      color: "#475569",

      "@media (max-width: 767.98px)": {
        textAlign: "center"
      }
    },

    leftBlockDescription: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "14.4px",
      textAlign: "left" as "left",
      color: "#475569",
      marginTop: 34,

      "@media (max-width: 767.98px)": {
        marginTop: 12
      }
    },

    leftBlockContent: {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "space-between"
    },

    rightBlock: {}
  });

export class LandingSalesAssets extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Paper className={classes.outerPaper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} md={3} className={classes.leftBlock}>
              <Box className={classes.leftBlockContent}>
                <Typography className={classes.leftBlockTitle}>
                  SALES ASSETS
                </Typography>

                <Typography className={classes.leftBlockDescription}>
                  Generate compelling 2-pagers, PSI papers, RFPs, and proposals infused with decision-making sciences.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={9} className={classes.rightBlock}>
              <Box className={classes.content}>
                <Typography className={classes.text}>COMING SOON!</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(LandingSalesAssets);
