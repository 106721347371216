import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const commonAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getFileTypesData: builder.query<any, any>({
      query: (params) => ({
        url: "/bx_block_cfdataintegrationviapromptengineering4/file_types",
        method: "get",
        params
      }),
      providesTags: ["Common"]
    }),
    getJobRoles: builder.query<any, any>({
      query: () => ({
        url: "/roles",
        method: "get"
      }),
      providesTags: ["Common"]
    }),
    getIndustries: builder.query<any, any>({
      query: () => ({
        url: "/bx_block_profile/industries",
        method: "get"
      }),
      providesTags: ["Common"]
    }),
    getTeamSize: builder.query<any, any>({
      query: () => ({
        url: "/bx_block_profile/company_sizes",
        method: "get"
      }),
      providesTags: ["Common"]
    }),
  }),
  overrideExisting: true
});

export const { useGetFileTypesDataQuery } = commonAPI;

// Create a function to call the API without using the hook
export const getFileTypesData = async (params): Promise<any> => {
  return apiCall(commonAPI.endpoints.getFileTypesData, params);
};

export const getJobRoles = async (): Promise<any> => {
  return apiCall(commonAPI.endpoints.getJobRoles);
};

export const getIndustries = async (): Promise<any> => {
  return apiCall(commonAPI.endpoints.getIndustries);
};

export const getTeamSize = async (): Promise<any> => {
  return apiCall(commonAPI.endpoints.getTeamSize);
};