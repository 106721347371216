import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as RegenerateChatMessageIcon } from '../../../../assets/icons/regenerate_copilot_message.svg';
import { ReactComponent as CopyChatMessageIcon } from '../../../../assets/icons/copy_chat_message_icon.svg';
import { ReactComponent as LikeChatMessageIcon } from '../../../../assets/icons/like_copilot_message.svg';
import { ReactComponent as DislikeChatMessageIcon } from '../../../../assets/icons/dislike_copilot_message.svg';
import { enqueueSnackbar } from "notistack";
import { likeMessage, dislikeMessage } from "../../../../redux/services/copilot";
import Markdown from "react-markdown";

import { useStyles } from "./styles";

const TypingText = ({ 
  id,
  chat,
  text, 
  speed,
  author,
  answerStatus,
  allowRefetch,
  allowAnimate, 
  scrollToBottom,
  setChat,
}) => {
  const [displayedText, setDisplayedText] = useState('');

  const classes = useStyles();
  
  useEffect(() => {
    if (author === 'user' || !allowAnimate) {
      setDisplayedText(text);

      scrollToBottom();
    } else {
      if (text.length) {
        scrollToBottom();

        // const characters = text.length <= 1000
        //   ? text.split('')
        //   : text.length > 1000
        //     ? ' ' + text.split(' ')
        //     : text.length > 3000
        //       ? ' ' + splitByWords(text, 3)
        //       : text.length > 6000
        //         ? ' ' + splitByWords(text, 6)
        //         : ' ' + splitByWords(text, 6)
        const characters = text.split('');
        let index = 0;
    
        const interval = setInterval(() => {
          setDisplayedText((prev) => prev + characters[index]);
          
          index++;
  
          if (index >= text.length) {
            clearInterval(interval);
          }
  
          // scrollToBottom();
        }, speed);
  
        return () => clearInterval(interval);
      }
    }
  }, [text, speed, author]);

  const handleCopyMesssage = () => {
    navigator.clipboard.writeText(text)
      .then(() => {
        enqueueSnackbar(
          'Copied to the clipboard',
          { variant: 'info' }
        )
      })
      .catch(() => {
        enqueueSnackbar(
          'Failed to copy',
          { variant: 'error' }
        )
      });
  }

  const handleLikeMessage = async () => {
    enqueueSnackbar(
      'Message liked',
      { variant: 'success' }
    )

    setChat({
      ...chat,
      messages: chat.messages.map(item => (
        item.id === id
          ? {
              ...item,
              answerStatus: 'LIKED'
            }
          : item
      ))
    })

    await likeMessage({ sessionId: chat.sessionId, messageId: id, });
  }

  const handleDislikeMessage = async () => {
    setChat({
      ...chat,
      messages: chat.messages.map(item => (
        item.id === id
          ? {
              ...item,
              answerStatus: 'DISLIKED'
            }
          : item
      ))
    })

    enqueueSnackbar(
      'Message disliked',
      { variant: 'success' }
    )

    await dislikeMessage({ sessionId: chat.sessionId, messageId: id, });
  }

  const handleRegenerateMessage = () => {
    
  }

  // const splitByWords = (sentence, groupSize) => {
  //   const words = sentence.split(' ');
  //   const result = [];
  
  //   for (let i = 0; i < words.length; i += groupSize) {
  //     result.push(words.slice(i, i + groupSize).join(' '));
  //   }
  
  //   return result;
  // }

  return (
    <Box style={{ width: '100%' }}>
      <Typography className={classes.message}>
        {!!displayedText.length
          ? displayedText !== 'error'
            ? <Markdown
                className={classes.markdown}
              >
                {displayedText}
              </Markdown>
            : <Box className={classes.errorMeesage}>
                Hmm... Something seems to have gone wrong while generating the response.
              </Box>
          : <Box className={classes.progressBar} />
        }
      </Typography>
      {!!displayedText.length && (
        <Box className={classes.actionsBox}>
          {author === 'copilot' && allowRefetch && displayedText === 'error' && (
            <IconButton 
              size="small"
              onClick={() => handleRegenerateMessage()}
            >
              <Tooltip
                arrow
                enterDelay={1000}
                title={'Regenerate message'}
                placement="bottom"
              >
                <RegenerateChatMessageIcon />
              </Tooltip>
            </IconButton>
          )}
          
          {displayedText !== 'error' && (
            <IconButton 
              size="small"
              onClick={() => handleCopyMesssage()}
            >
              <Tooltip
                arrow
                enterDelay={1000}
                title={'Copy'}
                placement="bottom"
              >
                <CopyChatMessageIcon />
              </Tooltip>
            </IconButton>
          )}

          {author === 'copilot' && answerStatus && displayedText !== 'error' && (
            <>
              <Box className={classes.verticalSeparator} />
              {(answerStatus === 'LIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  // disabled={answerStatus === 'LIKED'}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleLikeMessage()
                    }
                  }}
                  style={{
                    opacity: answerStatus === 'LIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'LIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'LIKED'
                        ? 'You have liked this response'
                        : 'Good response'
                    }
                    placement="bottom"
                  >
                    <LikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
              {(answerStatus === 'DISLIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  // disabled={answerStatus === 'DISLIKED'}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleDislikeMessage()
                    }
                  }}
                  style={{
                    opacity: answerStatus === 'DISLIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'DISLIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'DISLIKED'
                        ? 'You have disliked this response'
                        : 'Bad response'
                    }
                    placement="bottom"
                  >
                    <DislikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TypingText;
