import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  autocompleteOption: {
    fontFamily: "Montserrat",
    fontSize: '14px',
    fontWeight: 500,
    width: '100%',
    padding: '10px 12px',
    borderBottom: '1px solid #CBD5E1'
  },
  autocompleteListBox: {
    padding: 0,
    border: '1px solid #CBD5E1'
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    '@media (min-height: 1400px)': {
      marginTop: '-70px'
    },
  },
  viewBox: {
    width: '80%',
    maxWidth: '520px',
    // maxHeight: '720px',
    border: "1px solid #CBD5E1",
    background:
      "linear-gradient(145deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "28px",
    overflowX: 'hidden',
    overflowY: 'auto',
    // '@media (min-width: 1300px)': {
    //   margin: "0 auto"
    // },
  },
  createAccount: {
    textAlign: "center",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "6px",
    '@media (min-width: 600px) and (min-height: 800px)': {
      marginBottom: "8px",
    },
    '@media (min-height: 1000px)': {
      marginBottom: "12px",
    },
    color: "#000",
    fontFamily: "Montserrat"
  },
  validateError: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px"
  },
  signingAs: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#475569"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "0"
  },
  label: {
    margin: "0",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "black",
    fontFamily: "Montserrat",
    marginTop: "16px",
    '@media (min-height: 1000px)': {
      marginTop: "32px",
    },
  },
  locationSearch: {
    position: 'relative',
    '& input': {
      padding: '0px 0px 0px 40px !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      display: 'none'
    }
  },
  input: {
    height: "44px",
    marginTop: "5px",
    borderRadius: "8px",
    border: '1px solid #CBD5E1',
    background: "#FFF",
    overflow: 'hidden',
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px !important",
      "& fieldset": {
        borderColor: "transparent",
        height: "44px",
        border: 'none'
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-input": {
        height: "44px",
        padding: "10px 33px 10px 8px",
        lineHeight: "22px",
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Montserrat",
        color: "#000",
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1,
        color: '#64748B'
      },

      "& .MuiInputBase-input": {
        height: "auto",
      }
    }
  },
  locationIcon: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    left: '12px',
    top: '13px'
  },
  searchIcon: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    right: '12px',
    top: '13px'
  },
  eyeIcon: {
    paddingRight: "7px",
    color: "#94A3B8",
    cursor: "pointer"
  },
  button: {
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    background: "#000",
    boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    textTransform: "none",
    fontFamily: "Montserrat",
    color: "#fff",
    fontStyle: "normal",
    marginTop: "16px",
    '@media (min-height: 1000px)': {
      marginTop: "32px",
    },
    '&:hover': {
      background: "#000",
    }
  },
}));
