import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../../framework/main/RunEngine";
import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";

export default class ForgotPasswordAdapter {
  send: (message: Message) => void;
  context: any;

  constructor(context) {
    this.context = context;

    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationForgotPasswordMessage),
    ]);
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));

    to.addData(getName(MessageEnum.NavigationTargetMessage), "PasswordRecovery");
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {
        ...from.getData(getName(MessageEnum.NavigationPropsMessage)),
        ...this.context
      }
    );

    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(
      getName(MessageEnum.NavigationForgotPasswordPageInfo),
      from.getData(getName(MessageEnum.NavigationForgotPasswordPageInfo))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
