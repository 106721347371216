import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, Button, Modal, Tooltip, Typography } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import OnboardingHelperTooltip from '../../components/main/OnboardingHelperTooltip';
import { useHistory } from 'react-router-dom';

interface IProps {
  classes?: any;
  primaryHeading?: string;
  secondaryHeading?: string;
  onConfirm?: () => void;
  tooltips?: any;
  closeTooltip?: (section: string, key: string) => void;
}

class CompanyProgressHeader extends React.Component<IProps, {}> {
  render() {
    const {
      classes,
      primaryHeading = "Primary Heading",
      secondaryHeading = "Secondary Heading",
      tooltips,
      closeTooltip = () => {}
    } = this.props;

    return (
      <Box 
        className={classes.rootBreadCumbContainer}
      >
        <Box className={classes.headerContent}>
          <Box>
            <Typography align="center" className={classes.mainHeading}>
              {primaryHeading}
            </Typography>
          </Box>
          <Box>
            <Typography align="center" className={classes.subHeading}>
              {secondaryHeading}
            </Typography>
          </Box>
        </Box>
        <RenderAutoFillChip 
          id="autoFillChip" 
          onConfirm={this.props.onConfirm}
          tooltips={tooltips}
          closeTooltip={closeTooltip}
        />
      </Box>
    );
  }
}

export const RenderAutoFillChip: React.FC<{
  id?: string;
  onConfirm?: () => void;
  tooltips?: string;
  closeTooltip?: (section: string, key: string) => void;
}> = ({ 
  id, 
  onConfirm,
  tooltips,
  closeTooltip = () => {}
}) => {
  const [currentSection, setCurrentSection] = useState<string>('company');
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes('about-the-company')) {
      setCurrentSection('company');
    } else if (history.location.pathname.includes('about-your-approach')) {
      setCurrentSection('approach');
    } else if (history.location.pathname.includes('offering-the-company')) {
      setCurrentSection('offerings');
    }
  }, [history]);

  return (
    <>
      <Box
        style={styles.autofillWrapper as CSSProperties}
        id={id}
      >
        <Box style={styles.textWrapper as CSSProperties}>
          <Typography style={styles.autofillText as CSSProperties}>
            {'Activate the auto-fill tool to '}<span style={styles.autofillTextBold as CSSProperties}>{'automatically populate all sections using data'}</span>
            {'\nfrom your uploaded documents and website. Remember to review the content.'}
          </Typography>
        </Box>
        <OnboardingHelperTooltip
          open={!!id && tooltips[currentSection].autoFill}
          title={'Helper'}
          description={
            <>For faster onboarding, press the auto-fill button to populate <b>{currentSection === 'company' ? 'the Company' : currentSection === 'approach' ? 'Your Approach' : 'the Offerings'}</b> section.</>
          }
          placement='right'
          onClose={() => closeTooltip(currentSection, 'autoFill')}
        >
          <Button 
            onClick={() => setShowModal(true)} 
            style={styles.buttonActive as CSSProperties}
          >
            {'Auto-fill'}
          </Button>
        </OnboardingHelperTooltip>
      </Box>

      <Modal
        data-testid='modal-continue'
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={styles.modalWrapper as CSSProperties}>
          <div style={styles.closeBtn as CSSProperties}>
            <img src={require('../assets/close.svg').default} onClick={() => setShowModal(false)} width={16} height={16} />
          </div>
          <Typography style={styles.title as CSSProperties}>{'The info will be provided \n from your uploaded docs or website'}</Typography>
          <Typography style={styles.subTitle as CSSProperties}>{'This will '}<span style={styles.hightLight as CSSProperties}>{'replace'}</span>{' all manually entered text.'}</Typography>
          <div style={styles.btnWrapper as CSSProperties}>
            <Button style={styles.btnCancel as CSSProperties} onClick={() => setShowModal(false)}>{'No, leave my existing texts'}</Button>
            <Button style={styles.btnConfirm as CSSProperties} onClick={() => {
              setShowModal(false);
              onConfirm?.();
            }}>{'Yes, I want to replace text'}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const styles = createStyles({
  btnWrapper: {
    display: 'flex',
    marginTop: '32px',
    gap: '20px'
  },
  btnCancel: {
    textTransform: 'none',
    border: '1px solid #CBD5E1',
    background: "#fff",
    color: '#000',
    display: 'flex',
    height: '40px',
    "&:hover": {
      border: '1px solid #CBD5E1',
      background: "#fff",
      color: '#000',
    },
    flex: 1,
    borderRadius: '8px'
  },
  btnConfirm: {
    textTransform: 'none',
    border: '1px solid #000',
    background: "#000",
    color: '#fff',
    display: 'flex',
    borderRadius: '8px',
    "&:hover": {
      border: '1px solid #000',
      background: "#000",
      color: '#fff',
    },
    flex: 1,
    height: '40px'
  },
  rootBreadCumbContainer: {
    padding: '20px 0px',
    width: "100%"
  },
  title: {
    fontSize: '30px',
    fontWeight: 700,
    textAlign: 'center',
    whiteSpace: 'pre-line'
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#475569',
    marginTop: '12px',
    textAlign: 'center',
  },
  hightLight: {
    color: '#DC2626'
  },
  closeBtn: { 
    display: "flex", 
    alignItems: "center", 
    top: '40px', 
    right: '40px', 
    position: "absolute", 
    zIndex: 1000, 
    background: '#fff',
    cursor: 'pointer'
  },
  closeBtn2: {
    width: '25px',
    height: '25px',
    display: "flex", 
    alignItems: "center", 
    top: '-10px', 
    right: '-10px', 
    position: "absolute", 
    zIndex: 1000,
    cursor: 'pointer'
  },
  modalWrapper: {
    width: "847px",
    padding: "40px",
    borderRadius: "20px",
    '@media(max-width: 1024px)': {
      width: "90vw",
    },
    position: 'relative',
    background: "#FFF",
    display: 'flex',
    flexDirection: 'column'
  },
  bar2: {
    height: '4px',
    width: '80px',
    background: '#94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  bar1: {
    height: '4px',
    width: '80px',
    background: '#000',
    marginRight: '5px',
    borderRadius: '20px',
  },
  bar2Transparent: {
    height: '4px',
    width: '80px',
    border: '1px solid #94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  headerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column" as "column",
    margin: "0 0 20px 0"
  },
  autofillWrapper: {
    display: "flex",
    alignItems: "center",
    padding: '16px',
    borderLeft: '2px solid #A68B3D',
    background: 'rgb(250, 248, 244)',
    borderRadius: '8px',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    marginRight: '18px'
  },
  autofillText: {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    whiteSpace: 'pre-line',
  },
  autofillTextBold: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
  },
  buttonActive: {
    height: '32px',
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    background: "#000",
    textTransform: "none",
    fontFamily: "Montserrat",
    color: "#fff",
    fontStyle: "normal",
    width: '100px',
    "&:hover": {
      background: "#000"
    }
  },
  subHeading: {
    color: "#475569",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "18px",
  },
  mainHeading: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "48px",
  },
  alignStyle: {
    display: "flex ",
    justifyContent: "center",
    alignItems: "center",
  },
  chiptitle: {
    width: "100px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "12px",
    border: "1px solid #94A3B8",
    borderRadius: "20px",
    padding: "8px",
  },
});

export default withStyles(styles)(CompanyProgressHeader);
