export const trimTextByCharacters = (text, numberOfCharacters) => {
  if (text) {
    if (text.length <= numberOfCharacters) {
      return text;
    } else {
      const trimmedText = text.slice(0, numberOfCharacters);
  
      return trimmedText + '...';
    }
  } else {
    return '—';
  }
}

export const numberToWords = (numbet) => {
  const words = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];

  if (numbet >= 0 && numbet < 10) {
    return words[numbet];
  } else {
    return "Number out of range";
  }
}

export const boldWordInSentence = (sentence, word = null) => {
  if (word?.length) {
    const regex = new RegExp(`(${word})`, 'gi');
  
    return sentence.replace(regex, '<b>$1</b>');
  } else {
    return sentence;
  }
}