import React from 'react';
import { Field, Form, Formik } from "formik";
import { Modal, Box, IconButton, Typography, Button, Chip, InputLabel, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as AtSignIcon } from '../../../../assets/icons/at_sign.svg';
import CheckBoxIcon from '../../../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../../../assets/icons/dropdown_down.svg';
import { emailValidationRegex, validationSchema } from './validations';
import { PERMISSIONS } from '../../../../utils/enums';
import TextField from '../../../../components/main/CustomFields/TextField';
import Dropdown from '../../../../components/main/CustomFields/Dropdown/index';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onInvite: (values: any) => void;
}

const InviteUserModal: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onInvite
  } = props;

  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title}>
          Invite people to your team
        </Typography>
        <Box className={classes.form}>
          <Formik
            initialValues={{
              emailInput: "",
              emails: [],
              project: undefined,
              permissions: [],
              message: ""
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
            onSubmit={(values: any) => onInvite(values)}
          >
            {({
              values,
              errors,
              touched,
              submitForm,
              validateField,
              setFieldValue,
              setFieldError
            }) => (
              <Form className={classes.form}>
                <Box>
                  <TextField
                    required
                    name={'emailInput'}
                    label={'People emails'}
                    value={values['emailInput']}
                    placeholder={'Provide an email address'}
                    helperText={'Enter email addresses, separating them with commas'}
                    error={(touched['emails'] && errors['emails']) as string}
                    onChange={(e) => {
                      setFieldValue('emailInput', e.target.value);

                      const emails = e.target.value.split(',').map(email => email.trim()).filter(email => emailValidationRegex.test(email));

                      const uniqueEmails = new Set(emails);

                      setFieldValue('emails', [...uniqueEmails as any]);
                    }}
                  />

                  {!!values.emails.length && (
                    <Box style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '4px' }}>
                      {values.emails.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          // deleteIcon={<CloseIcon />}
                          onDelete={() => {
                            const newEmailInputValue = values.emailInput
                              .split(',')
                              .filter(email => email.trim() !== item)
                              .join(',');

                            setFieldValue('emailInput', newEmailInputValue);
                            setFieldValue('emails', values.emails.filter(elem => elem !== item));
                          }}
                          style={{
                            borderRadius: '16px',
                            padding: '4px 6px',
                            background: '#F1F5F9',
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            color: '#334155',
                            display: 'flex',
                            columnGap: '10px'
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Box>

                <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Dropdown
                    required
                    name={'project'}
                    label={'Project'}
                    value={values['project']}
                    placeholder={'Choose option...'}
                    options={[
                      { value: 1, label: 'Coca Cola' },
                      { value: 2, label: 'Apple' },
                    ]}
                    error={(touched['project'] && errors['project']) as string}
                    onChange={(event, value) => setFieldValue('project', event.target.value)}
                  />
                  <Dropdown
                    required
                    name={'permissions'}
                    label={'Permissions'}
                    value={values['permissions']}
                    placeholder={'Choose option...'}
                    optionsType={'checkboxes'}
                    options={Object.values(PERMISSIONS).map(item => ({ value: item, label: item }))}
                    error={(touched['permissions'] && errors['permissions']) as string}
                    onChange={(event, value) => setFieldValue('permissions', value)}
                  />
                </Box>

                <TextField
                  name={'message'}
                  label={
                    <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#000000', marginBottom: '4px' }}>
                      Text message &nbsp;
                      <Typography style={{ display: 'inline', fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                        (optional)
                      </Typography>
                    </Typography>
                  }
                  value={values['message']}
                  placeholder={'Enter message to your colleague'}
                  type={'textarea'}
                  maxLength={1000}
                  error={(touched['message'] && errors['message']) as string}
                  onChange={(event) => setFieldValue('message', event.target.value)}
                />

                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<AtSignIcon />}
                  className={classes.inviteButton}
                >
                  Send invitation(s)
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default InviteUserModal;
