import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/subscriptions`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getSubscriptionById: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/license/subscriptions/${id}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getPaymentsHistory: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/payments`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    createOrder: builder.query<any, any>({
      query: ({ uuid, body }) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/orders?accountId=${uuid}`,
        method: "post",
        body
      })
    }),
    getCurrentSubscription: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    cancelSubscription: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "post"
      })
    }),
    addPaymentMethod: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/payments`,
        method: "post"
      })
    }),
    renewSubscription: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "put"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getSubscriptions = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getSubscriptions);
};

export const getSubscriptionById = async (id): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getSubscriptionById, id);
};

export const getPaymentsHistory = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getPaymentsHistory);
};

export const createOrder = async (uuid, body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createOrder, { uuid, body });
};

export const getCurrentSubscription = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getCurrentSubscription);
};

export const cancelSubscription = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.cancelSubscription);
};

export const addPaymentMethod = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.addPaymentMethod);
};

export const renewSubscription = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.renewSubscription);
};
