import React from "react";
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  ArrowBackIos,
  DeleteOutline,
  InsertDriveFile,
  SaveAlt,
} from "@material-ui/icons";
import clsx from "clsx";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Dropdown from "../../../components/main/Dropdown.web";
import Toast from "../../utilities/main/Toast";
import BulkUploadingCompanyInfoController, {
  Props,
} from "./BulkUploadingCompanyInfoController.web";
import BulkUploadingCompanyInfoDialog from "./BulkUploadingCompanyInfoDialog.web";
import BulkUploadingCompanyInfoHighlightIcon from "./BulkUploadingCompanyInfoHighlightIcon.web";
import { background, logo } from "./assets";
import { v4 as uuidv4 } from "uuid";
import { convertFromBytes, getFileExtension } from "../../../helpers/file";
import UploadZone from "../../../components/main/CustomFields/UploadZone";
import TextField from "../../../components/main/CustomFields/TextField";
import RadioButton from "../../../components/main/CustomFields/RadioButton";

const styles = () =>
  createStyles({
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100%",
      width: "100%",
    },
    container2: {
      background: 'transparent',
      height: "fit-content",
      width: "100%",
      '& $form': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
      }
    },
    logo: {
      mixBlendMode: "multiply",
      position: "absolute",
      top: 20,
      left: 40,
    },

    backBtn: {
      position: "absolute",
      top: 84,
      left: 40,
      borderRadius: "50%",
      backgroundColor: "transparent",
      padding: 12,
      border: "1px solid #E2E8F0",
      cursor: "pointer",
    },

    backBtnIcon: {
      fontSize: 16,
    },

    inner: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 1,
    },

    form: {
      position: 'relative',
      boxSizing: "border-box",
      padding: 40,
      borderRadius: 28,
      maxWidth: 848,
      width: "100%",
      background:
        "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },

    formTop: {

    },

    formHeading: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "32px",
    },

    formHeadingIcon: {
      position: "absolute",
      top: 5,
      right: 5,
    },

    formDescription: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      textAlign: "center",
      marginTop: 12,
      maxWidth: 536,
    },

    fileSectionWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginTop: 32,
    },

    fileInput: {
      width: "100%",
    },

    dropfileWrapper: {
      borderRadius: 20,
      width: "100%",
      border: "1px solid #CBD5E1",
      background: "#3A84C90D",
      height: 136,
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
    },

    uploadIcon: {
      fontSize: 24,
      color: "#3A84C9",
    },

    uploadTitle: {
      marginTop: 8,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "22px",
      display: "flex",
    },

    chooseFile: {
      color: "#3A84C9",
      textDecorationLine: "underline",
      fontWeight: 600,
      marginLeft: 4,
    },

    input: {
      display: "none",
    },

    fileNoted: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: "0 6px",
      marginTop: 8,
    },

    noted: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "22px",
      color: "#8C8C8C",
    },

    action: {
      marginTop: 32,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: '20px'
    },

    cancelBtn: {
      borderRadius: 8,
      border: "1px solid #CBD5E1",
      fontWeight: 600,
      fontSize: 16,
      lineheight: "19.2px",
      textTransform: "capitalize",
      padding: "10px 16px",
      width: "100%",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
    },

    submitBtn: {
      borderRadius: 8,
      backgroundColor: "#000000",
      marginLeft: 20,
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: 16,
      lineheight: "19.2px",
      textTransform: "capitalize",
      padding: "10px 16px",
      width: "100%",
      cursor: "pointer",
    },

    fileWrapper: {
      marginTop: 24,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    itemWrapper: {
      display: "flex",
      padding: "8px 0px 18px 35px",
      marginRight: "22px",
      alignItems: "center",
    },

    fileScrollview: {
      overflowY: "auto",
      overflowX: "hidden",
      marginRight: "13px",
      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#CBD5E1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#64748B",
        borderRadius: 8,
      },
    },

    fileIcon: {
      width: 35,
      height: 35,
      color: "#E04E4E",
      marginRight: "15px",
    },

    fileContent: {
      display: "flex",
      alignItems: "center",
    },

    fileInfo: {
      width: 350,
    },

    font16: {
      fontSize: "16px",
      lineHeight: "22px",
    },

    font12: {
      fontSize: "12px",
      lineHeight: "22px",
      fontWeight: 400,
    },

    fontWeight600: {
      fontWeight: 600,
    },

    deleteBtn: {
      marginLeft: "auto",
    },

    dropdown: {
      marginTop: 0,
      width: 200,
      borderRadius: 8,
    },

    dropownInput: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      color: "#22507B",
      padding: "8px 12px",
      border: "1px solid #CBD5E1",
      borderRadius: 8,
    },

    deleteBtnIcon: {
      fontSize: 16,
    },

    fileSelectInner: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 12,
    },

    errorMessage: {
      color: "#DC2626",
      marginTop: "2px",
      fontSize: "12px",
      lineHeight: 1.2,
      fontWeight: 500,
    },
  });

const urlRegex = /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(\/[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const websiteWayValidationSchema = Yup.object().shape({
  website: Yup.string()
    .nullable()
    .matches(urlRegex, "This link is invalid.")
    .required("Please enter company website."),
});

const documentsWayValidationSchema = Yup.object().shape({
  data: Yup.object().test(
    "has-non-empty-property",
    "Please choose file type",
    (object: { [key: string]: number }) => {
      const errors: any = {};

      Object.entries(object).forEach(([key, value]) => {
        if (value <= 0) {
          errors[key] = "Please choose file type";
        }
      });

      if (Object.keys(errors).length === 0) {
        return true;
      }

      throw new Yup.ValidationError(errors, object, "data");
    }
  ),
});

export class BulkUploadingCompanyInfo extends BulkUploadingCompanyInfoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 9999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box className={this.props.view === 'modal' ? classes.container2 : classes.container}>
          {(!this.props.view || this.props.view !== 'modal') && (
            <img src={logo} className={classes.logo} />
          )}

          <Box className={classes.inner}>
            <Formik
              innerRef={this.formRef as any}
              validateOnChange={false}
              initialValues={{
                website: '',
                data: {},
              }}
              validationSchema={this.state.way === 'website' ? websiteWayValidationSchema : documentsWayValidationSchema}
              onSubmit={this.uploadMultipleFile}
            >
              {({
                errors,
                values,
                submitCount,
                touched,
                setTouched,
                validateField,
                setFieldValue,
                setErrors
              }) => (
                <Box className={classes.form}>
                  <Box className={classes.formTop}>
                    <Typography component="h1" className={classes.formHeading}>
                      Provide your company information
                    </Typography>

                    {!this.autofetch && !this.state.loading && (!this.props.view || this.props.view !== 'modal') && (
                      <Box className={classes.formHeadingIcon}>
                        <BulkUploadingCompanyInfoHighlightIcon
                          highlight={this.state.highlightInfo}
                          onClick={this.toggleInfoDialog}
                          onSubmit={this.toggleHighlightInfo}
                        />
                      </Box>
                    )}
                  </Box>

                  <Typography
                    component="span"
                    className={classes.formDescription}
                  >
                    This trains your own dedicated AI, familiarising it with your business.
                    The more information you provide, the more intelligent it becomes.
                  </Typography>

                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%', margin: '45px 0px 32px 0px' }}>
                    <RadioButton
                      checked={this.state.way === 'website'}
                      label={"Enter your website URL:"}
                      onChange={() => {
                        this.handleChooseWay('website')
                        setErrors({})
                        this.setState({
                          error: ''
                        })
                      }}
                    />
                    <Box
                      style={{
                        opacity: this.state.way === 'documents' ? '0.5' : '1',
                        pointerEvents: this.state.way === 'documents' ? 'none' : 'all'
                      }}
                    >
                      <TextField
                        name={'website'}
                        value={values['website']}
                        placeholder={'Company website'}
                        error={(touched['website'] && errors['website']) as string}
                        onChange={(e) => setFieldValue('website', e.target.value)}
                      />
                    </Box>
                  </Box>

                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
                    <RadioButton
                      checked={this.state.way === 'documents'}
                      label={"Or, upload your company info as a document:"}
                      onChange={() => {
                        this.handleChooseWay('documents')
                        setErrors({})
                        this.setState({
                          error: ''
                        })
                      }}
                    />
                    {this.state.webFiles.length < 3 && (
                      <Box
                        style={{
                          opacity: this.state.way === 'website' ? '0.5' : '1',
                          pointerEvents: this.state.way === 'website' ? 'none' : 'all'
                        }}
                      >
                        <UploadZone
                          formats={['pdf', 'docx', 'txt']}
                          size={{
                            number: 25,
                            unit: 'MB',
                          }}
                          error={this.state.error}
                          multiple
                          onChange={(successful, erroneous) => {
                            if (successful.length || erroneous.length) {
                              this.onChangeFile(successful, erroneous);

                              if (successful.length) {
                                let newData = { ...values.data };
                                Array.from(successful).slice(0, 3).forEach((file: any) => {
                                  newData = { ...newData, [file.id]: 0 };
                                });

                                setFieldValue("data", newData);
                              }
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Box>

                  <Form className={classes.fileWrapper} translate={undefined}>
                    <Box
                      className={classes.fileScrollview}
                      style={{
                        opacity: this.state.way === 'website' ? '0.5' : '1',
                        pointerEvents: this.state.way === 'website' ? 'none' : 'all'
                      }}
                    >
                      {this.state.webFiles.map((file) => {
                        return (
                          <Box
                            className={classes.itemWrapper}
                            key={file.id}
                            data-test-id={"file-" + file.id}
                          >
                            <InsertDriveFile
                              className={classes.fileIcon}
                              style={{
                                color: this.getColor(file.name),
                              }}
                            />
                            <Box className={classes.fileContent}>
                              <Box className={classes.fileInfo}>
                                <Typography
                                  noWrap
                                  className={clsx(
                                    classes.font16,
                                    classes.fontWeight600,
                                    classes.fileName
                                  )}
                                >
                                  {file.name}
                                </Typography>

                                <Typography className={classes.font12}>
                                  {getFileExtension(file.type) +
                                    " • " +
                                    convertFromBytes(file.size)}
                                </Typography>
                              </Box>

                              <Box className={classes.fileSelectInner}>
                                <Dropdown
                                  containerClassName={classes.dropdown}
                                  name={file.id}
                                  dataTestId={`select-${file.id}`}
                                  value={(values.data as any)[file.id] || 0}
                                  dataList={this.state.fileOptions}
                                  hasError={
                                    errors.data &&
                                      (touched.data as any)[file.id]
                                      ? (errors.data as any)[file.id]
                                      : false
                                  }
                                  handleChange={(e) => {
                                    const data: any = values.data;
                                    data[file.id] = Number(e.target.value);
                                    setFieldValue("data", data);
                                    setTouched(
                                      {
                                        data: {
                                          ...touched.data as object,
                                          [file.id]: true,
                                        },
                                      },
                                      false
                                    );
                                    if (submitCount) {
                                      validateField("data");
                                    }
                                    this.handleChangeFileInfo(
                                      file.id,
                                      Number(e.target.value)
                                    );
                                  }}
                                />

                                {errors.data &&
                                  (touched.data as any)[file.id] ? (
                                  <Typography
                                    data-test-id={`error-${file.id}`}
                                    component="span"
                                    className={classes.errorMessage}
                                  >
                                    {(errors.data as any)[file.id]}
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Box>

                            <IconButton
                              className={classes.deleteBtn}
                              data-test-id={"delete-file-" + file.id}
                              onClick={() => {
                                const data: any = values.data;
                                delete data[file.id];
                                setFieldValue("data", data);
                                this.removeFileWeb(file.id);
                              }}
                            >
                              <DeleteOutline
                                className={classes.deleteBtnIcon}
                              />
                            </IconButton>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box className={classes.action}>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          this.props.view === 'modal'
                            ? this.props.onClose()
                            : this.skipNow()
                        }}
                      >
                        {this.props.view === 'modal'
                          ? 'Cancel'
                          : 'Skip'
                        }
                      </Button>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          if (!Object.keys(values.data).length && this.state.way === 'documents') {
                            this.setState({
                              error: 'Please attach file'
                            })
                          }
                        }}
                      >
                        Save & Continue
                      </Button>
                    </Box>
                  </Form>
                </Box>
              )}
            </Formik>
          </Box>

          {/* <button className={classes.backBtn} onClick={this.onBack}>
            <ArrowBackIos className={classes.backBtnIcon} />
          </button> */}
        </Box>

        <BulkUploadingCompanyInfoDialog
          open={this.state.infoDialog}
          onSubmit={this.toggleInfoDialog}
          onClose={this.toggleInfoDialog}
        />
      </>
    );
  }
}

export default withStyles(styles)(BulkUploadingCompanyInfo);
