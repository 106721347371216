import React from "react";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useStyles } from "./styles";

const WelcomePage = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const handleLogin = () => {
    history.push('/login');
  }

  const handleSignUp = () => {
    history.push('/signup');
  }

  return (
    <Box className={classes.containter}>
      <Typography style={{ textAlign: 'center', fontSize: '30px', fontWeight: '700', lineHeight: '32px' }}>
        Welcome to Pebbles Ai
      </Typography>
      <Typography style={{ marginTop: '4px', marginBottom: '26px', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
        Your go to market journey starts here.
      </Typography>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => handleLogin()}
      >
        Log in
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => handleSignUp()}
      >
        Sign up
      </Button>
    </Box>
  );
};

export default WelcomePage;
