import React from "react";
import { Switch } from "react-router-dom";

import WebRoutesGenerator from "./components/main/NativeWebRouteWrapper";

import { Providers } from "./providers";
import { routeMap } from "./helpers/routes";

const App = () => {
  return (
    <Providers>
      <Switch>
        {WebRoutesGenerator(routeMap)}
      </Switch>
    </Providers>
  );
};

export default App;
