import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";
import StorageProvider from "../../../framework/main/StorageProvider";
import { chipLabels, goodToHaveServiceArray, inputList, offeringsBenefits, offeringsCorporatePartnersInputList, offeringsCorporatePartnersServiceArray, offeringsProductArray, offeringsProductsInputList, offeringsServicesArray, offeringsServicesInputList, offeringsTeamInputList, performanceAnalyticsQuestionList, performanceAnalyticsServiceArr, productTestimonial, productTestimonialInputList, serviceArray, serviceArray1, shouldHaveServiceArray, offeringsgoodToHaveInputList, offeringsModularProductsServiceArray, offeringsModularProductsInputList } from "../utils/data";
import { ONBOARDING_STEPS, STEPS_TYPE } from "../../../utils/enums";
import { offeringsOnboradingData } from "../../../helpers/onboarding";
import { getOnboardingData, saveOnboardingData, triggerAutofillOnboarding } from "../../../redux/services/onboarding";
import { enqueueSnackbar } from "notistack";
import { observableService } from "../../../services/observable";

interface InputItem {
  offering: string;
  description: string;
  offeringTitle: string;
  descriptionTitle: string
}

export const initialOfferValues: any = {
  whyExist: ["All-round GTM platform"],
  what_is_it: "",
  performanceAnalystics: ["", "", ""],
  productTestimonials: [""],
  corporatePartners: [
    {
      benifit: "",
      partner: [
        {
          name: "",
          description: "",
          benifit: "",
        },
        {
          name: "",
          description: "",
          benifit: "",
        },
        {
          name: "",
          description: "",
          benifit: "",
        },
      ],
    },
  ],
  productAndFeature: [
    {
      title: "",
      description: "",
    },
    {
      title: "",
      description: "",
    },
    {
      title: "",
      description: "",
    },
  ],
  offeringServices: [
    {
      title: "",
      description: "",
    },
    {
      title: "",
      description: "",
    },
    {
      title: "",
      description: "",
    },
  ],
  theTeam: [
    {
      offering: "",
      description: "",
    },
    {
      offering: "",
      description: "",
    },
    {
      offering: "",
      description: "",
    },
  ],
  modularProducts: [
    {
      name: "",
      description: "",
      price: "",
      currency: "null",
    },
    {
      name: "",
      description: "",
      price: "",
      currency: "null",
    },
    {
      name: "",
      description: "",
      price: "",
      currency: "null",
    },
  ],
  packagesAndPricing: [
    {
      offering: "",
      description: "",
      price: "",
      currency: "null",
    },
    {
      offering: "",
      description: "",
      price: "",
      currency: "null",
    },
    {
      offering: "",
      description: "",
      price: "",
      currency: "null",
    }
  ]
};

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  token?: string;
  autoFillEmitter?: any;
  setAutoSaveData?: any;
  autoSavedValues?: any;
  formRef?: any;
  setFormRef?: any;
  hideBackBtn?: any;
  closeTooltip?: (section: string, key: string) => void;
  tooltips?: any;
}

interface S {
  serviceArray: string[];
  serviceArray1: string[];
  shouldHaveServiceArray: string[];
  goodToHaveServiceArray: string[];
  inputList: any;
  headeractiveStep: any;
  headerprevCompletedStep: any;
  mustHaveExpanded: boolean;
  shouldHaveExpanded: boolean;
  goodToHaveExpanded: boolean;
  chipLabels: any;
  performanceAnalyticsQuestionList: any;
  performanceAnalyticsServiceArr: any;
  offeringsProductArray: string[];
  offeringsServicesArray: string[];
  offeringsProductsInputList: {
    offeringPlaceholder: string;
    descriptionPlaceholder: string;
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string;
    charLength?: number;
    charLengthDesc?: number;
    wordsLength?: number;
    wordsLengthDesc?: number;
  }[],
  offeringsTeamInputList: {
    offeringPlaceholder: string;
    descriptionPlaceholder: string;
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string;
    charLength: number;
    charLengthDesc: number
  }[],
  offeringsServicesInputList: {
    offeringPlaceholder: string;
    descriptionPlaceholder: string;
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string;
    charLength?: number;
    charLengthDesc?: number;
    wordsLength?: number;
    wordsLengthDesc?: number;
  }[],
  productTestimonial: any;
  productTestimonialInputList: any;
  offeringsgoodToHaveInputList: any;
  offeringsModularProductsServiceArray: any;
  offeringsModularProductsInputList: any;
  offeringsCorporatePartnersServiceArray: any;
  offeringsCorporatePartnersInputList: any;
  offeringsBenefits: any;
  progressHeaderSubStep: any;
  progressHeaderTime: any;
  progressHeaderType: any;
  progressHeaderPrimaryHeading: any;
  progressHeaderSecondaryHeading: any;
  alreadyFilledFormValues: any;
  loading: boolean;
  clearAllInfoDialog: boolean;
  skipModalOpen: boolean;
  setAutoSaveData?: any;
  formRef?: any;
  showUploadDocumentsModal?: boolean;
}

interface SS {
  id: any;
}

export default class OfferingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchAPIDataMsgId: string = "";
  completedSteps: any;
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      loading: false,
      headeractiveStep: 3,
      headerprevCompletedStep: 2,
      offeringsProductArray: offeringsProductArray,
      offeringsServicesArray: offeringsServicesArray,
      offeringsProductsInputList: offeringsProductsInputList,
      offeringsTeamInputList: offeringsTeamInputList,
      offeringsServicesInputList: offeringsServicesInputList,
      productTestimonial: productTestimonial,
      productTestimonialInputList: productTestimonialInputList,
      performanceAnalyticsQuestionList: performanceAnalyticsQuestionList,
      performanceAnalyticsServiceArr: performanceAnalyticsServiceArr,
      shouldHaveServiceArray: shouldHaveServiceArray,
      serviceArray: serviceArray,
      serviceArray1: serviceArray1,
      goodToHaveServiceArray: goodToHaveServiceArray,
      offeringsgoodToHaveInputList: offeringsgoodToHaveInputList,
      offeringsModularProductsServiceArray: offeringsModularProductsServiceArray,
      offeringsModularProductsInputList: offeringsModularProductsInputList,
      offeringsCorporatePartnersServiceArray: offeringsCorporatePartnersServiceArray,
      offeringsCorporatePartnersInputList: offeringsCorporatePartnersInputList,
      offeringsBenefits: offeringsBenefits,
      inputList: inputList,
      chipLabels: chipLabels,
      progressHeaderSubStep: "1",
      progressHeaderTime: "5",
      progressHeaderType: "gold",
      progressHeaderPrimaryHeading: "About your offerings",
      progressHeaderSecondaryHeading:
        "Finally, describe your suite of products, features and services in more detail.",
      alreadyFilledFormValues: {},
      clearAllInfoDialog: false,
      skipModalOpen: false,
      setAutoSaveData: null,
      formRef: null,
      showUploadDocumentsModal: false
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.props.autoFillEmitter(this.getAPIData);

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.setQueryParam('partOfStep', 'mustHaveExpanded');

    this.setState({
      mustHaveExpanded: true,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      progressHeaderSubStep: 1
    });

    this.fetchInitData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS): void {
    if (
      (prevState.mustHaveExpanded !== this.state.mustHaveExpanded) ||
      (prevState.shouldHaveExpanded !== this.state.shouldHaveExpanded) ||
      (prevState.goodToHaveExpanded !== this.state.goodToHaveExpanded)
    ) {
      const {
        mustHaveExpanded,
        shouldHaveExpanded,
        goodToHaveExpanded
      } = this.state;
      const trueKey = Object
        .keys({
          mustHaveExpanded,
          shouldHaveExpanded,
          goodToHaveExpanded
        })
        .find(key => this.state[key] === true);

      this.setQueryParam('partOfStep', trueKey || 'goodToHaveExpanded');
    }
  }

  async componentWillUnmount() {
    this.setState({
      alreadyFilledFormValues: {}
    })
  }

  setQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({}, '', url);
  };

  openSkipConfirmationDialog = () => {
    this.setState({
      skipModalOpen: true
    })
  };

  closeSkipConfirmationDialog = () => {
    this.setState({
      skipModalOpen: false
    })
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        switch (apiRequestCallId) {
          case this.fetchAPIDataMsgId:
            this.handleDataFromAPI(responseJson);
            break;
        }
      }
    }

    this.setState({
      loading: false
    });
  }

  checkValue = (key: string, condition: any, value: any) => {
    return condition ? value : initialOfferValues[key];
  }

  checkValInArray = (cond: boolean, data: string[], position: number) => {
    return cond ? Object.values(data[position]) : ['']
  }

  handleDataFromAPI = (response: any) => {
    this.setState({
      loading: false
    });

    if (!response.errors && !response.error) {
      const mustHaveSection = {
        productAndFeature: this.checkValue('productAndFeature', response.ProductsFeatures.length, response.ProductsFeatures.map((item: any, index: number) => ({
          title: item[`OfferingTitle_${index + 1}`],
          description: item[`Description_${index + 1}`]
        }))),
        offeringServices: this.checkValue('offeringServices', response.Services.length, response.Services.map((item: any, index: number) => ({
          title: item[`OfferingTitle_${index + 1}`],
          description: item[`Description_${index + 1}`]
        }))),
      }

      const shouldHaveSection = {
        theTeam: this.checkValue('theTeam', response.TheTeam.length, response.TheTeam.map((item: any, index: number) => ({
          offering: item[`NameTitle_${index + 1}`],
          description: item[`Description_${index + 1}`]
        }))),
        productTestimonials: this.checkValInArray(response.ProductTestimonials.length, response.ProductTestimonials, 0),
        performanceAnalystics: this.checkValInArray(response.PerformanceAnalytics.length, response.PerformanceAnalytics, 0),
      }
      const defaultPartner = initialOfferValues.corporatePartners[0].partner;
      const partner = !response.CorporatePartners.length ? defaultPartner : response.CorporatePartners.map((item: any, index: number) => ({
        name: item[`OrgName_${index + 1}`],
        description: item[`Description_${index + 1}`],
        benifit: item[`Benefit_${index + 1}`]
      }))

      const goodHaveSection = {
        corporatePartners: [{
          benifit: response.CorporatePartnersBenefit || '',
          partner: partner
        }],
        modularProducts: this.checkValue('modularProducts', response.ModularProductsServices.length, response.ModularProductsServices.map((item: any, index: number) => ({
          name: item[`OfferingTitle_${index + 1}`],
          price: item[`Price_${index + 1}`],
          description: item[`Description_${index + 1}`]
        }))),
        packagesAndPricing: this.checkValue('packagesAndPricing', response.PackagesPricing.length, response.PackagesPricing.map((item: any, index: number) => ({
          offering: item[`PackageName_${index + 1}`],
          description: item[`Description_${index + 1}`],
          price: item[`Price_${index + 1}`],
        })))
      }

      this.setState({
        goodToHaveExpanded: true,
        chipLabels: this.state.chipLabels || chipLabels,
        shouldHaveExpanded: true,
        mustHaveExpanded: true,
        alreadyFilledFormValues: {
          ...initialOfferValues,
          ...this.state.alreadyFilledFormValues,
          ...mustHaveSection,
          ...shouldHaveSection,
          ...goodHaveSection,
        },
      })
    } else {
      this.parseApiErrorResponse(response);
    }
  }


  getAPIData = async () => {
    enqueueSnackbar(
      `We are preparing AI. It could take some time. Don't refresh and don't leave the page.`,
      { variant: 'info' }
    );

    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      progressHeaderSubStep: 1
    });

    this.setState({
      loading: true
    })

    const { data, error } = await triggerAutofillOnboarding({ types: 'AC_OFFERING' });

    if (!data || error) {
      if (error?.status === 402) {
        observableService.sendEvent('Show top up modal for ONBOARDING');
      } else if (error?.status === 422) {
        enqueueSnackbar(
          `Please, upload the documents or provide website URL`,
          { variant: 'info' }
        );

        this.setState({
          showUploadDocumentsModal: true
        })
      } else {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }

      this.setState({
        mustHaveExpanded: true,
        shouldHaveExpanded: false,
        goodToHaveExpanded: false,
        progressHeaderSubStep: 1
      });
    } else if (Array.isArray(data)) {
      observableService.sendEvent('Increase usage amount');

      enqueueSnackbar(
        `Offerings auto-filled successfully`,
        { variant: 'success' }
      );

      const alreadyFilledFormValues = {
        whyExist: [],
        // what_is_it: "",
        performanceAnalystics: ["", "", ""],
        productTestimonials: [""],
        corporatePartners: [
          {
            benifit: "",
            partner: [
              {
                name: "",
                description: "",
                benifit: ""
              },
              {
                name: "",
                description: "",
                benifit: ""
              },
              {
                name: "",
                description: "",
                benifit: ""
              }
            ]
          }
        ],
        productAndFeature: [
          {
            title: "",
            description: ""
          },
          {
            title: "",
            description: ""
          },
          {
            title: "",
            description: ""
          }
        ],
        offeringServices: [
          {
            title: "",
            description: ""
          },
          {
            title: "",
            description: ""
          },
          {
            title: "",
            description: ""
          }
        ],
        theTeam: [
          {
            offering: "",
            description: ""
          },
          {
            offering: "",
            description: ""
          },
          {
            offering: "",
            description: ""
          }
        ],
        modularProducts: [
          {
            name: "",
            description: "",
            price: "",
            currency: "null"
          },
          {
            name: "",
            description: "",
            price: "",
            currency: "null"
          },
          {
            name: "",
            description: "",
            price: "",
            currency: "null"
          }
        ],
        packagesAndPricing: [
          {
            offering: "",
            description: "",
            price: "",
            currency: "null"
          },
          {
            offering: "",
            description: "",
            price: "",
            currency: "null"
          },
          {
            offering: "",
            description: "",
            price: "",
            currency: "null"
          }
        ]
      };

      data.forEach(item => {
        if (item.parameter_name === 'what_semantic_keywords') {
          alreadyFilledFormValues.whyExist = item.value || []
        }
        if (item.parameter_name === 'team') {
          item.value.lenght
            ? item.value.forEach((item, index) => {
              alreadyFilledFormValues.theTeam[index].offering = item.full_name + ' | ' + item.title
              alreadyFilledFormValues.theTeam[index].description = item.bio
            })
            : [
              {
                offering: "",
                description: ""
              },
              {
                offering: "",
                description: ""
              },
              {
                offering: "",
                description: ""
              }
            ]
        }
        if (item.parameter_name === 'performance_analytics') {
          alreadyFilledFormValues.performanceAnalystics = item.value.length ? item.value : ["", "", ""]
        }
        if (item.parameter_name === 'product_and_feature') {
          alreadyFilledFormValues.productAndFeature = item.value.length ? item.value : [
            {
              title: "",
              description: ""
            },
            {
              title: "",
              description: ""
            },
            {
              title: "",
              description: ""
            }
          ];
        }
        if (item.parameter_name === 'service') {
          alreadyFilledFormValues.offeringServices = item.value.length 
            ? item.value.map(item => ({ offering: item.title, description: item.description }))
            : [
                {
                  offering: "",
                  description: ""
                },
                {
                  offering: "",
                  description: ""
                },
                {
                  offering: "",
                  description: ""
                }
              ]
        }
        if (item.parameter_name === 'modular_products_and_services') {
          item.value.length
            ? item.value.forEach((item, index) => {
                alreadyFilledFormValues.modularProducts[index].name = item.name
                alreadyFilledFormValues.modularProducts[index].description = item.description
                alreadyFilledFormValues.modularProducts[index].price = item.price
                alreadyFilledFormValues.modularProducts[index].currency = item.currency
              })
            : [
                {
                  name: "",
                  description: "",
                  price: "",
                  currency: "null"
                },
                {
                  name: "",
                  description: "",
                  price: "",
                  currency: "null"
                },
                {
                  name: "",
                  description: "",
                  price: "",
                  currency: "null"
                }
              ]
        }
        if (item.parameter_name === 'product_testimonial') {
          alreadyFilledFormValues.productTestimonials = item.value.length
            ? item.value
            : [""]
        }
        if (item.parameter_name === 'package_and_pricing') {
          item.value.length
            ? item.value.forEach((item, index) => {
                alreadyFilledFormValues.packagesAndPricing[index].offering = item.name
                alreadyFilledFormValues.packagesAndPricing[index].description = item.description
                alreadyFilledFormValues.packagesAndPricing[index].price = item.price
                alreadyFilledFormValues.packagesAndPricing[index].currency = item.currency
              })
            : [
                {
                  offering: "",
                  description: "",
                  price: "",
                  currency: "null"
                },
                {
                  offering: "",
                  description: "",
                  price: "",
                  currency: "null"
                },
                {
                  offering: "",
                  description: "",
                  price: "",
                  currency: "null"
                }
              ]
        }
        if (item.parameter_name === 'corporate_partnership') {
          item.value.length
            ? item.value.forEach((item, index) => {
              alreadyFilledFormValues.corporatePartners[index].benifit = item.benefits_description
              alreadyFilledFormValues.corporatePartners[index].partner[index] = item.partners
            })
            : [
              {
                benifit: "",
                partner: [
                  {
                    name: "",
                    description: "",
                    benifit: ""
                  },
                  {
                    name: "",
                    description: "",
                    benifit: ""
                  },
                  {
                    name: "",
                    description: "",
                    benifit: ""
                  }
                ]
              }
            ]
        }
      })

      this.state.setAutoSaveData('offerings', alreadyFilledFormValues);
      this.state.formRef.setValues(alreadyFilledFormValues);
      this.state.formRef.validateForm();

      // if (!Object.keys(this.state.formRef?.errors)?.length) {
      //   this.saveAndContinueButton(alreadyFilledFormValues, true, true);
      // }

      this.setState({
        mustHaveExpanded: true,
        shouldHaveExpanded: false,
        goodToHaveExpanded: false,
        progressHeaderSubStep: 1
      });

      this.setState({
        alreadyFilledFormValues: alreadyFilledFormValues,
      })
    }

    this.setState({
      loading: false
    })
  }

  backButton = async () => {
    const isEdit = await StorageProvider.getItem('isEdit')
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      !!isEdit ? "LandingPage" : "BulkUploading"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  // priceValidation = (data: any) => {
  //   return ((data?.toString().split('.')[1] || []).length) <= 3;
  // }

  fetchInitData = async () => {
    this.setState({
      loading: true
    })

    const { data } = await getOnboardingData();

    if (data && Array.isArray(data) && data.length) {
      const {
        mustHaveSection,
        shouldHaveSection,
        goodHaveSection
      } = offeringsOnboradingData(data);

      const alreadyFilledFormValues = {
        ...initialOfferValues,
        ...mustHaveSection,
        ...shouldHaveSection,
        ...goodHaveSection
      };

      this.state.formRef?.setValues(alreadyFilledFormValues);
      this.state.formRef?.validateForm();

      this.setState({
        chipLabels: mustHaveSection.whyExist || chipLabels,
        alreadyFilledFormValues,
      })
    } else {
      this.setState({
        alreadyFilledFormValues: {}
      })
    }

    this.setState({
      loading: false
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
    const list: any = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list })
  }
  handleExpand = () => {
    this.setState({
      mustHaveExpanded: !this.state.mustHaveExpanded,
      shouldHaveExpanded: false,
      goodToHaveExpanded: false,
      progressHeaderSubStep: 1
    });
  }
  handleExpand1 = () => {
    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: !this.state.shouldHaveExpanded,
      goodToHaveExpanded: false,
      progressHeaderSubStep: 2
    });
  }
  handleExpand2 = () => {
    this.setState({
      mustHaveExpanded: false,
      shouldHaveExpanded: false,
      goodToHaveExpanded: !this.state.goodToHaveExpanded,
      progressHeaderSubStep: 3
    });
  }
  deleteChipByName = (nameToDelete: any) => {
    this.setState((prevState) => ({
      chipLabels: prevState.chipLabels.filter((label: any) => label !== nameToDelete),
    }));
  };

  checkValidation = (touch: boolean, error: any) => {
    return !!touch && !!error ? error : ''
  }

  addChipLabels = (value: string) => {
    const { chipLabels } = this.state;
    if (value.trim() !== '') {
      // Create a new array with the new item added to the end
      const updatedItems = [...chipLabels, value];
      this.setState({
        chipLabels: updatedItems,
      });
    }
  }

  handleAddObjectItem = () => {
    const { inputList } = this.state;

    // Create a new item and add it to the inputList
    const newItem = {
      label: "Step",
      placeholder: "New Placeholder",
      value: "",
      name: `step${inputList.length + 1}`,
      charLength: 30
    };

    this.setState({
      inputList: [...inputList, newItem]
    });
  }


  handleCommonObjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    listName: keyof S, // Use keyof to specify the list name
    propertyName: string
  ) => {
    const { value } = event.target;
    this.setState((prevState): any => {
      const updatedList = [...prevState[listName]];
      updatedList[index][propertyName] = value;
      return { [listName]: updatedList };
    });
  };

  addTestimonial = (value: string) => {
    this.setState((prev) => ({
      productTestimonialInputList: [...prev.productTestimonialInputList,
      {
        label: "Testimonial",
        placeholder: "Paul Walker | CEO | Kumoco | B2B | SaaS | Cloud Tech Our GTM strategy and execution capabilities in conjunction with our execution support has pushed our incoming marketing qualified leads by 444%, reduced our marketing execution cost by 76%, and landed our first demo for an Enterprise client in month 6",
        value: "",
        name: "",
        charLength: 300
      },
      ]
    }))
  }

  addProductAnalytics = (value: string) => {
    this.setState((prev) => ({
      performanceAnalyticsQuestionList: [...prev.performanceAnalyticsQuestionList,
      {
        label: `Analytics`,
        placeholder: "100% of our B2B / B2B2C Clients witnessed accelerated growth.",
        value: "",
        name: `analytics${this.state.performanceAnalyticsQuestionList.length + 1}`,
        charLength: 30
      },
      ]
    }))
  }

  clearAllInformation = async (values: any) => {
    this.handleExpandSection('none');

    await this.mustHaveOfferOnSubmit(values, true);
    await this.shouldOfferHaveOnSubmit(values, true);
    await this.goodOfferToHaveOnSubmit(values, true);

    this.setState({ alreadyFilledFormValues: {} });

    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.handleExpandSection('mustHaveExpanded');

      enqueueSnackbar(
        `Data has been cleared successfully`,
        { variant: 'success' }
      );

      this.setState({
        loading: false
      });
    }, 2000);
  };

  saveAndContinueButton = (values: any, shadowSave?: boolean, entireSaving?: boolean) => {
    if (entireSaving) {
      this.setState({
        loading: true
      })

      this.mustHaveOfferOnSubmit(values, shadowSave);
      this.shouldOfferHaveOnSubmit(values, shadowSave);
      this.goodOfferToHaveOnSubmit(values, shadowSave);

      this.setState({
        loading: false
      })
    } else {
      if (this.state.mustHaveExpanded) {
        this.mustHaveOfferOnSubmit(values, shadowSave);
      } else if (this.state.shouldHaveExpanded) {
        this.shouldOfferHaveOnSubmit(values, shadowSave);
      } else {
        this.goodOfferToHaveOnSubmit(values, shadowSave);
      }
    }
  }

  handleExpandSection = (targetSection) => {
    this.setState({
      mustHaveExpanded: targetSection === 'mustHaveExpanded',
      shouldHaveExpanded: targetSection === 'shouldHaveExpanded',
      goodToHaveExpanded: targetSection === 'goodToHaveExpanded',
    })
  }

  async mustHaveOfferOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      details_type: "must",
      object_type: "offerings",
      what_keywords: formValues.whyExist,
      product_features_attributes: formValues.productAndFeature,
      offering_services_attributes: formValues.offeringServices
    }

    const { data } = await saveOnboardingData(body);

    if (data) {
      if (!shadowSave) {
        this.handleExpandSection('shouldHaveExpanded');
      }
    } else {
      if (!shadowSave) {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }
    }

    this.setState({
      loading: false
    });
  }

  async shouldOfferHaveOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      details_type: "should",
      object_type: "offerings",
      the_team: formValues.theTeam,
      product_testimonials: formValues.productTestimonials,
      performance_analystics: formValues.performanceAnalystics,
    };

    const { data } = await saveOnboardingData(body);

    if (data) {
      if (!shadowSave) {
        this.handleExpandSection('goodToHaveExpanded');
      }
    } else {
      if (!shadowSave) {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }
    }

    this.setState({
      loading: false
    });
  }

  async goodOfferToHaveOnSubmit(formValues: any, shadowSave?: boolean) {
    this.setState({
      loading: !shadowSave
    });

    const body = {
      details_type: "good",
      object_type: "offerings",
      corporate_partners: formValues.corporatePartners?.length ? formValues.corporatePartners[0] : {},
      modular_products: formValues.modularProducts,
      // the_team: formValues.theTeam,
      packages_and_pricing: formValues.packagesAndPricing,
      // offering_services: formValues.offeringServices,
      // product_and_feature: formValues.productAndFeature,
      // product_testimonials: formValues.productTestimonials,
      // performance_analystics: formValues.performanceAnalystics,
      // why_exist: formValues.whyExist
    };

    const { data } = await saveOnboardingData(body);

    if (data) {
      if (!shadowSave) {
        this.redirectToPage('/home');

        enqueueSnackbar(
          `Your Offerings section copleted successfully!`,
          { variant: 'success' }
        );
      }
    } else {
      if (!shadowSave) {
        enqueueSnackbar(
          `Something went wrong. Please try again.`,
          { variant: 'error' }
        );
      }
    }

    this.setState({
      loading: false
    });
  }

  onSendDataToBuilderChain = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const messageAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    messageAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    messageAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    messageAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.buidlerChainAPI
    );
    runEngine.sendMessage(messageAPI.id, messageAPI);
  }

  async redirectToPage(path: string) {
    await StorageProvider.removeItem('isEdit');

    this.props.navigation.navigate(path);
  }

  closeClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: false
    })
  }

  openClearAllInfoDilog = () => {
    this.setState({
      clearAllInfoDialog: true
    })
  }

  redirectToStep = (url) => {
    this.props.navigation.navigate(url);
  }
}
