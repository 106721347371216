import React from "react";
import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";

import StorageProvider from "../../../framework/main/StorageProvider";
import { parseErrors } from "../../utilities/main/Toast";
import { enqueueSnackbar } from "notistack";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  token?: string;
}

interface S {
  pathDetails: {
    id: string;
    type: string;
    attributes: {
      path_name: string;
      duration: string;
      path_description: string;
    };
    borderColor: string;
    color: string;
  }[];
  successMessage: string;
  isLoad: boolean;
}

interface SS {
  id: any;
}

export default class ChooseYourPathController extends BlockComponent<
  Props,
  S,
  SS
> {
  displayPathDetailsID: string = "";
  addPathDetailsID: string = "";
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      pathDetails: [],
      successMessage: "",
      isLoad: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.getPathDetails();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      await this.handlePathDetails(requestCallId, message);
      await this.handleAddPathDetails(requestCallId, message);
    }
  }

  async handlePathDetails(requestCallId: string, message: Message) {
    if (this.displayPathDetailsID === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        if (responseJson.data.length === 1) {
          this.handlePath(responseJson.data[0].id)
        } else {
          this.setState({
            pathDetails: responseJson.data
          });
        }
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }

  async handleAddPathDetails(requestCallId: string, message: Message) {
    if (this.addPathDetailsID === requestCallId) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (response && response.message) {
        this.handleNextScreen();
      } else if (response && response.errors) {
        const errors = parseErrors(response);

        enqueueSnackbar(
          errors,
          { variant: 'error' }
        )
      }
    }
  }

  getPathDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token
    };
    this.displayPathDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleBack = async () => {
    const isBusiness = await StorageProvider.getItem("isBusiness");
    const screen = isBusiness ? "CompanyAbout" : "AboutYourself";
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  generateBorderColor = (index: number) => {
    const colors = ["#A68B3D", "#3A84C9", "#57BE99"];
    return colors[index % colors.length];
  };

  generateFontColor = (index: number) => {
    const colors = ["transparent", "#22507B", "#059669"];
    return colors[index % colors.length];
  };

  handlePath = (id: string) => {
    const requestPathMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token
    };
    this.addPathDetailsID = requestPathMessage.messageId;
    requestPathMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetPath +
      "/" +
      `${id}` +
      configJSON.apiEndPointAddPath
    );
    requestPathMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestPathMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(requestPathMessage.id, requestPathMessage);
  };

  handleNextScreen = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BeforeWeStart"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
}

