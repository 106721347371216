import React from "react";

import { Grid, Box, Typography } from "@material-ui/core";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "./InfoIcon.web";

interface IProps {
  title: string;
  servicesList: string[];
  modalContent: any;
  headerName?: string;
}

export default class CustomList extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={webStyle.infoBox}>
            <Typography style={webStyle.servicesHead}>
              {this.props.title}{this.props.headerName === "MUST HAVE" ? '*' : ''}
            </Typography>
            <InfoIcon htmlContent={this.props.modalContent} />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            style={
              this.props.servicesList.length > 4
                ? webStyle.serviceListCount
                : webStyle.serviceList
            }
          >
            {this.props.servicesList.map((data, index) => (
              <Box style={webStyle.maxCharacterBox} key={index}>
                <FiberManualRecordIcon style={webStyle.listIcon} />
                <Typography style={webStyle.maxCharacter}>{data}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={8} />
      </Grid>
    );
  }
}

const webStyle = {
  infoBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  servicesHead: {
    fontWeight: 600
  },
  listIcon: {
    fontSize: "0.5rem",
    color: "#475569"
  },
  maxCharacter: {
    fontSize: "0.87rem",
    color: "#475569"
  },
  maxCharacterBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  serviceList: {
    padding: "1% 1% 2.5%"
  },
  serviceListCount: {
    padding: "1% 1% 2.5%",
    columnCount: 2
  }
};
