import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tooltipWrapper: {
    zIndex: 1,
    pointerEvents: 'all',
  },
  tooltipBox: {
    display: 'flex', 
    flexDirection: 'column', 
    gap: '12px', 
    width: '300px', 
    maxWidth: '300px', 
    background: '#FFFFFF',
    borderRadius: '28px', 
    padding: '18px',
    boxShadow: '0px 8px 32px 0px #0000000F',
    // boxShadow: '0px 4px 8px 0px #00000008',
    position: 'relative',
    [theme.breakpoints.down(1540)]: {
      width: '250px',
      maxWidth: '250px',
    },
    [theme.breakpoints.down(1435)]: {
      width: '200px',
      maxWidth: '200px',
    },
  },
  tooltipTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '15px', 
    fontWeight: 600, 
    lineHeight: '21px', 
    color: '#000000',
  },
  tooltipDescription: {
    fontSize: '13px', 
    fontWeight: 500, 
    lineHeight: '19px', 
    color: '#090A14'
  },
  tooltipArrow: {
    color: 'white'
  },
  tooltipClose: {
    position: 'absolute',
    top: '15px',
    right: '15px'
  },
}));