import React from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, ListItemText, MenuItem, Radio, Select, TextField, Typography } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete, createFilterOptions } from '@material-ui/lab';

import { useStyles } from './styles';

interface Props {
  name?: string;
  value: any;
  placeholder?: string;
  options: { value: string | number, label: string | number }[];
  optionsType?: 'default' | 'checkboxes';
  label?: string;
  required?: boolean;
  error?: string;
  inputAdornment?: any;
  onChange: (event: any, value: any) => void;
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: { label: string, value: string }) => option.label,
});

const Autocomplete: React.FC<Props> = (props) => {
  const classes = useStyles({ label: props.label, error: props.error });

  return (
    <FormControl
      error={!!props.error}
      variant="standard"
      className={classes.wrapper}
    >
      {props.label && (
        typeof props.label === 'string'
          ? <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#000000', marginBottom: '4px' }}>
              {props.label} &nbsp;
              {props.required && (
                <Typography style={{ color: 'red' }}>
                  *
                </Typography>
              )}
            </Typography>
          : props.label
      )}
      <FormControlLabel
        control={
          <MuiAutocomplete
            closeIcon={false}
            options={props.options}
            getOptionLabel={(option: any) => option.label || ''}
            filterOptions={filterOptions}
            classes={{
              root: classes.select,
            }}
            renderInput={(params: any) => (
              <TextField 
                placeholder={props.placeholder} 
                {...params} 
              />
            )}
            onChange={props.onChange}
          />
        }
        label={''}
        className={classes.controll}
        name={props.name}
        value={props.value || ''}
        onChange={props.optionsType === 'checkboxes' ? () => { } : props.onChange}
      />
      {props.inputAdornment && (
        <Box className={classes.inputAdornment}>
          {props.inputAdornment}
        </Box>
      )}
      {props.error && (
        <FormHelperText error>
          {props.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Autocomplete;
