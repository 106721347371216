import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const copilotAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query<any, any>({
      query: () => ({
        url: `/v1/chatbot/chats`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getChatById: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/chatbot/chats/${id}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    sendChatQuestion: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/chatbot/chats`,
        method: "post",
        body // { "question": "string", chatType: "string" }
      })
    }),
    sendChatQuestionById: builder.mutation<any, any>({
      query: ({ body, sessionId }) => ({
        url: `/v1/chatbot/chats/${sessionId}`,
        method: "put",
        body // { "question": "string", chatType: "string" }
      })
    }),
    deleteChat: builder.mutation<any, any>({
      query: (id) => ({
        url: `/v1/chatbot/chats/${id}`,
        method: "delete"
      })
    }),
    likeMessage: builder.mutation<any, any>({
      query: ({ sessionId, messageId }) => ({
        url: `/v1/chatbot/chats/${sessionId}/like?messageId=${messageId}`,
        method: "post"
      })
    }),
    dislikeMessage: builder.mutation<any, any>({
      query: ({ sessionId, messageId }) => ({
        url: `/v1/chatbot/chats/${sessionId}/dislike?messageId=${messageId}`,
        method: "post"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getChats = async (): Promise<any> => {
  return apiCall(copilotAPI.endpoints.getChats);
};

export const getChatById = async (id): Promise<any> => {
  return apiCall(copilotAPI.endpoints.getChatById, id);
};

export const sendChatQuestion = async (body): Promise<any> => {
  return apiCall(copilotAPI.endpoints.sendChatQuestion, body);
};

export const sendChatQuestionById = async ({
  body,
  sessionId
}): Promise<any> => {
  return apiCall(copilotAPI.endpoints.sendChatQuestionById, {
    body,
    sessionId
  });
};

export const deleteChat = async (id): Promise<any> => {
  return apiCall(copilotAPI.endpoints.deleteChat, id);
};

export const likeMessage = async ({ sessionId, messageId }): Promise<any> => {
  return apiCall(copilotAPI.endpoints.likeMessage, { sessionId, messageId });
};

export const dislikeMessage = async ({
  sessionId,
  messageId
}): Promise<any> => {
  return apiCall(copilotAPI.endpoints.dislikeMessage, { sessionId, messageId });
};
