import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
// import { robotCoin, robotLove, robotBlock } from "../main/assets";
import { ReactComponent as Robot_2 } from "../../../assets/robots/robot_2.svg";
import { ReactComponent as Robot_3 } from '../../../assets/robots/robot_3.svg';
import { ReactComponent as Robot_4 }  from '../../../assets/robots/robot_4.svg';
import LandingBlockWeb from "./LandingBlock.web";

export interface Props extends WithStyles<any> {
  onClick: (index: number) => void;
}

const styles = () =>
  createStyles({
    group: {
      display: "flex",
      height: "100%",
    },

    item: {},

    container: {
      padding: 20,
      borderRadius: 28,
      display: "flex",
      boxSizing: "border-box",
      flexDirection: "column",
      position: "relative",
      minHeight: 160,
      height: "100%",
      cursor: 'pointer'
    },

    image: {
      position: "absolute",
      top: 0,
      right: 20,
      width: "auto",
      height: 82,
    },

    title: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "19.2px",
    },

    content: {
      marginTop: 45,
    },

    description: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "14.4px",
      color: "#475569",
      marginTop: 8,
    },
  });

const taticalOutReaches = [
  {
    image: <Robot_2 />,
    reachTitle: "Marketing Emails",
    reachDescription: "Powerful one-touch emails and campaigns.",
    callMadeColor: "#57BE99",
    firstTypoColor: "#57BE99",
    activeStep: 1,
  },

  {
    image: <Robot_3 />,
    reachTitle: "Sales Emails",
    reachDescription: "Persuasive emails for leads and customers.",
    callMadeColor: "#d93855",
    firstTypoColor: "#d93855",
    activeStep: 2,
  },

  {
    image: <Robot_4 />,
    reachTitle: "Product Emails",
    reachDescription: "Effective user onboarding and feedback emails.",
    callMadeColor: "#3A84C9",
    firstTypoColor: "#3A84C9",
    activeStep: 3,
  },
];

export class LandingTaticalOutReach extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <LandingBlockWeb
        title="TACTICAL OUTREACH"
        content="Engage your audience with targeted emails using neuromarketing and advanced personalisation."
      >
        <Grid container spacing={2} className={classes.group}>
          {taticalOutReaches.map((item, index) => (
            <Grid 
              item 
              xs={12} 
              sm={4} 
              key={index}
              className={classes.item}
              onClick={() => this.props.onClick(item.activeStep)}
            >
              <Paper 
                className={classes.container}
              >
                <ArrowForwardIcon
                  style={{
                    color: item.callMadeColor,
                    transform: "rotate(-45deg)",
                    // cursor: 'pointer'
                  }}
                />

                <Box className={classes.image}>
                  {item.image}
                </Box>
                {/* <img className={classes.image} src={item.image} /> */}

                <Box className={classes.content}>
                  <Typography
                    className={classes.title}
                    style={{ color: item.firstTypoColor }}
                  >
                    {item.reachTitle}
                  </Typography>
                  <Typography className={classes.description}>
                    {item.reachDescription}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </LandingBlockWeb>
    );
  }
}

export default withStyles(styles)(LandingTaticalOutReach);
