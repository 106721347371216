import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const authenticationAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.query<any, any>({
      query: (body) => ({
        url: `/bx_block_login/logins`,
        method: "POST",
        body: {
          data: {
            type: "email_account",
            attributes: body
          }
        }
      })
    }),
    loginUserJava: builder.query<any, any>({
      query: (body) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/auth/login`,
        method: "POST",
        body
      })
    }),
    signUpUser: builder.query<any, any>({
      query: (body) => ({
        url: `/account_block/accounts`,
        method: "POST",
        body
      })
    }),
    sendResetPasswordLink: builder.query<any, any>({
      query: (body) => ({
        url: `/bx_block_forgot_password/passwords`,
        method: "POST",
        body
      })
    }),
    saveNewPassword: builder.query<any, any>({
      query: ({ params, body }) => ({
        url: `/resetpassword?token=${params.token}&email=${params.email}`,
        method: "PATCH",
        body
      })
    }),
    sendOtpCode: builder.query<any, any>({
      query: (body) => ({
        url: `/account_block/accounts/resent_signup_otp`,
        method: "POST",
        body
      })
    }),
    confirmOtpCode: builder.query<any, any>({
      query: (body) => ({
        url: `/otp_confirmation`,
        method: "POST",
        body
      })
    }),
    updateAccountDetails: builder.query<any, any>({
      query: (body) => ({
        url: `/account_block/accounts/update_account`,
        method: "POST",
        body
      })
    }),
    getReferralCode: builder.query<any, any>({
      query: (uuid) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/referral-code?accountId=${uuid}`,
        method: "GET"
      })
    }),
    setReferralCodeAccount: builder.query<any, any>({
      query: ({ uuid, code }) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/referred-account/referred/${uuid}?referralCode=${code}`,
        method: "PUT"
      })
    }),
    getReferralsAmount: builder.query<any, any>({
      query: (uuid) => ({
        baseUrl: process.env.REACT_APP_API_URL_V2,
        url: `/v1/license/referred-account/owner/${uuid}/amount`,
        method: "GET"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const loginUser = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.loginUser, body);
};

export const loginUserJava = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.loginUserJava, body);
};

export const signUpUser = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.signUpUser, body);
};

export const sendResetPasswordLink = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.sendResetPasswordLink, body);
};

export const saveNewPassword = async ({ params, body }): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.saveNewPassword, { params, body });
};

export const sendOtpCode = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.sendOtpCode, body);
};

export const confirmOtpCode = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.confirmOtpCode, body);
};

export const updateAccountDetails = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.updateAccountDetails, body);
};

export const getReferralCode = async (uuid): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.getReferralCode, uuid);
};

export const setReferralCodeAccount = async (uuid, code): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.setReferralCodeAccount, { uuid, code });
};

export const getReferralsAmount = async (uuid): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.getReferralsAmount, uuid);
};