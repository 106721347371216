import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import StorageProvider from "../framework/main/StorageProvider";
import { store } from "./store";
import { ApiResponse } from "./types";

let refreshPromise: Promise<string> | null = null;

export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const baseQueryWithAuth = fetchBaseQuery({
      baseUrl: args.baseUrl || process.env.REACT_APP_API_URL,
      prepareHeaders: async (headers) => {
        const tokenRuby = await StorageProvider.getItem("user-token");
        const tokenJava = await StorageProvider.getItem("user-token-java");

        if (tokenJava) headers.set("Authorization", `Bearer ${tokenJava}`);
        if (tokenRuby) headers.set("Token", `${tokenRuby}`);

        return headers;
      }
    });

    let result = await baseQueryWithAuth(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      if (!refreshPromise) {
        refreshPromise = handleRefreshToken().then((newToken) => {
          refreshPromise = null;
          return newToken;
        }).catch((error) => {
          refreshPromise = null;
          console.error("Token refresh failed:", error);
          return Promise.reject(error);
        });
      }

      try {
        const newToken = await refreshPromise;
        await StorageProvider.setItem("user-token", newToken);
        
        // Retry the original request with the new token
        result = await baseQueryWithAuth(args, api, extraOptions);
      } catch (refreshError) {
        // Optionally handle logout or notify user
        return { error: { status: 401, data: "Unauthorized" } };
      }
    }

    return result;
  },
  tagTypes: [
    "Common", 
    "Profile", 
    "UserManagement"
  ],
  endpoints: () => ({})
});

const handleRefreshToken = async () => {
  const refresh = await StorageProvider.getItem("refresh-token");
  if (!refresh) throw new Error("No refresh token available");

  const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/auth/refresh-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ token: refresh })
  });
  
  if (!response.ok) throw new Error("Failed to refresh token");

  const data = await response.json();
  await StorageProvider.setItem("user-token-java", data.accessToken);
  await StorageProvider.setItem("refresh-token", data.refreshToken);

  return data.accessToken;
};

export const apiCall = async (
  endpoint: any,
  params: any = null,
  options: any = {
    forceRefetch: true
  }
): Promise<any> => {
  const result = await store.dispatch(
    endpoint.initiate(params, options)
  );

  const response: ApiResponse = {
    data: result.data
  };

  if ("error" in result) {
    response.error = result.error;
  }

  return response;
};
