import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const TermsAndConditions = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Typography style={{ textAlign: 'center', fontSize: '48px', fontWeight: '600', lineHeight: '58px' }}>
          Terms & Conditions
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
          Updated 1 Nov 2024
        </Typography>
        <Box className={classes.content}>
          <Typography className={classes.title}>
            1. Introduction
          </Typography>
          <Typography className={classes.description}>
            Here at Pebbles.AI we aim to work in a fair and transparent manner as such we ask everyone to reach these terms and conditions which apply between you, the User of this Website, and GTMSAI Ltd (trading as Pebbles.Ai), the owner and operator of this Website. 
            <br /><br />
            Please read these terms and conditions carefully, as they affect your legal rights. Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use of the Website. If you do not agree to be bound by these terms and conditions, you should stop using the Website immediately.
            <br /><br />
            In these terms and conditions, User or Users means any third party that accesses the Website and is not either (i) employed by Pebbles.Ai and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Pebbles.ai and accessing the Website in connection with the provision of such services.
            <br /><br />
            You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms and conditions, you represent and warrant that you are at least 18 years of age.
          </Typography>
          <Typography className={classes.title}>
            2. Intellectual property and acceptable use
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                All Content included on the Website, unless uploaded by Users, is the property of Pebbles.Ai, our affiliates or other relevant third parties. In these terms and conditions, Content means any text, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website, including any such content uploaded by Users. By continuing to use the Website you acknowledge that such Content is protected by copyright, trademarks, database rights and other intellectual property rights. Nothing on this site shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on the site without the owner’s prior written permission.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                You may, for your own personal, non-commercial use only, do the following:
              </Typography>
              <ul 
                className={classes.ul}
                style={{ margin: '0' }}
              >
                <li>
                  <Typography className={classes.description}>
                    retrieve, display and view the Content on a computer screen
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.description}>
                    print one copy of the Content
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography className={classes.description}>
                You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any Content without the written permission of Pebbles.Ai.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            3. Prohibited use
          </Typography>
          <Typography className={classes.description}>
            You may not use the Website for any of the following purposes:
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                in any way which causes, or may cause, damage to the Website or interferes with any other person’s use or enjoyment of the Website;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable or in breach of any applicable law, regulation, governmental order;
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                making, transmitting or storing electronic copies of Content protected by copyright without the permission of the owner.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            4. Links to other websites
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                The Site may, from time to time, include links to external sites. We include these to provide you with access to information, products or services that you may find useful or interesting. 
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                We are not responsible for the content of these sites or for anything provided by them and do not guarantee that they will be continuously available. The fact that we include links to such external sites does not imply any endorsement of or association with their operators or promoters.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                We assume no responsibility for the content of such Websites and disclaim liability for any and all forms of loss or damage arising out of the use of them.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            5. Privacy Policy and Cookies Policy
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are incorporated into these terms and conditions by this reference. To view the Privacy Policy and Cookies Policy, please click on the following:
              </Typography>
              <ul 
                className={classes.ul}
                style={{ margin: '0' }}
              >
                <li>
                  <Typography className={classes.description}>
                    <a href="https://app.trypebbles.ai/privacy-policy">https://app.trypebbles.ai/privacy-policy</a>
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.description}>
                    <a href="https://app.trypebbles.ai/cookies">https://app.trypebbles.ai/cookies</a>
                  </Typography>
                </li>
              </ul>
            </li>
          </ul>
          <Typography className={classes.title}>
            6. Availability of the Website and disclaimers
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                Any online facilities, tools, services or information that Pebbles.ai makes available through the Website (the Service) is provided “as is” and on an “as available” basis. We give no warranty that the Service will be free of defects and/or faults. To the maximum extent permitted by the law, we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality. Pebbles.ai is under no obligation to update information on the Website.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Whilst Pebbles.Ai. uses reasonable endeavours to ensure that the Website is secure and free of errors, viruses and other malware, we give no warranty or guaranty in that regard and all Users take responsibility for their own security, that of their personal details and their computers.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Pebbles.Ai accepts no liability for any disruption or non-availability of the Website.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Pebbles.Ai reserves the right to alter, suspend or discontinue any part (or the whole of) the Website including, but not limited to, any products and/or services available. These terms and conditions shall continue to apply to any modified version of the Website unless it is expressly stated otherwise.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            7. Limitation of liability
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                Any online facilities, tools, services or information that Pebbles.ai makes available through the Website (the Service) is provided “as is” and on an “as available” basis. We give no warranty that the Service will be free of defects and/or faults. To the maximum extent permitted by the law, we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality. Pebbles.ai is under no obligation to update information on the Website.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your liabilities in any way that is not permitted under applicable law.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                We will not be liable to you in respect of any losses arising out of events beyond our reasonable control.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                To the maximum extent permitted by law, Pebbles.Ai accepts no liability for any of the following:
              </Typography>
              <ul 
                className={classes.ul}
                style={{ margin: '0' }}
              >
                <li>
                  <Typography className={classes.description}>
                    any business losses, such as loss of profits, income, revenue, anticipated savings, business, contracts, goodwill or commercial opportunities;
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.description}>
                    loss or corruption of any data, database or software;
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.description}>
                    any special, indirect or consequential loss or damage.
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography className={classes.description}>
                Unfortunately, we cannot guarantee the continuous, uninterrupted or error-free operability of our Site. There may be times when certain features, parts or content of the Site, or the entire Site, become unavailable (whether on a scheduled or unscheduled basis) or are modified, suspended or withdrawn by us, in our sole discretion, without notice to you.  You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of the Site, or any features, parts or content of the Site.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            8. General
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                You may not transfer any of your rights under these terms and conditions to any other person. We may transfer our rights under these terms and conditions where we reasonably believe your rights will not be affected.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                These terms and conditions may be varied by us from time to time. Such revised terms will apply to the Website from the date of publication. Users should check the terms and conditions regularly to ensure familiarity with the then current version.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                These terms and conditions together with the Privacy Policy and Cookies Policy contain the whole agreement between the parties relating to its subject matter and supersede all prior discussions, arrangements or agreements that might have taken place in relation to the terms and conditions.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and conditions and no third party will have any right to enforce or rely on any provision of these terms and conditions.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                If any court or competent authority finds that any provision of these terms and conditions (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these terms and conditions will not be affected.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                This Agreement shall be governed by and interpreted according to the law of England and Wales and all disputes arising under the Agreement (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English and Welsh courts.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.title}>
            9. Pebbles.Ai details
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Typography className={classes.description}>
                The responsible company and trading entity is GTMS AI Ltd of 82 Wandsworth Bridge Road, London, United Kingdom, SW6 2TF trading as Pebbles.Ai.
              </Typography>
            </li>
            <li>
              <Typography className={classes.description}>
                You can contact Pebbles.Ai through our contact form or by email to <a href="mailto:corporate@trypebbles.ai">corporate@trypebbles.ai</a>.
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
};

export default TermsAndConditions;
