import { Box, Button, Modal, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Clear from "@material-ui/icons/Clear";

interface IProps {
  htmlContent: string;
  size?: number;
  iconColor?: string;
}

const useStyles = makeStyles({
  container: {
    display: "flex"
  },
  infoIcon: {
    cursor: "pointer"
  },
  infoIconBig: {
    height: "24px",
    width: "24px",
    color: "#3A84C9"
  },
  boxWrapper: {
    background: "#fff",
    borderRadius: "20px",
    padding: "40px",
    width: "100%",
    maxWidth: "520px",
    display: "flex",
    flexDirection: "column"
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  contentWrapper: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    padding: "20px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    color: "#475569"
  },
  content: {
    display: "flex",
    flex: 1,
    margin: "28px 0"
  },
  button: {
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    background: "#000",
    textTransform: "none",
    color: "white",
    "&:hover": {
      background: "#000"
    }
  }
});

const InfoIcon = ({
  htmlContent,
  size = 20,
  iconColor = "#3A84C9"
}: IProps) => {
  const classes = useStyles();
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Box
        className={classes.container}
        onClick={e => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        <InfoOutlined
          className={classes.infoIcon}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            color: iconColor
          }}
        />
      </Box>

      <Modal
        className={classes.modal}
        open={visible}
        onClose={() => setVisible(false)}
      >
        <Box className={classes.contentWrapper}>
          <Box className={classes.boxWrapper}>
            <Box className={classes.header}>
              <InfoOutlined className={classes.infoIconBig} />
              <Clear
                className={classes.closeIcon}
                onClick={() => setVisible(false)}
              />
            </Box>
            <Box className={classes.content}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div style='font-family:Montserrat,sans-serif !important; line-height: normal;'>${htmlContent}</div>`
                }}
              />
            </Box>
            <Button
              variant="contained"
              fullWidth
              type="button"
              onClick={() => setVisible(false)}
              className={classes.button}
            >
              Thanks
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default InfoIcon;
