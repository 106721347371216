import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";
import StorageProvider from "../framework/main/StorageProvider";
import { matchRoute, routeMap } from "../helpers/routes";
import { clearStorages } from "../helpers/other";
import { getProfile, deleteProfile } from "../redux/services/profile";
import { getCurrentSubscription } from "../redux/services/subscriptions";

const AuthenticationProvider = ({ children, location, history }) => {
  const [prevUrl, setPrevUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleFetchUser();
  }, [location.pathname, history]);

  const handleFetchUser = async () => {
    const token = await StorageProvider.getItem("user-token");

    setPrevUrl(location.pathname);

    const nextUrl = location.pathname;

    const prevRoute = Object.values(routeMap).find((item) =>
      matchRoute(prevUrl, item.path)
    );
    const nextRoute = Object.values(routeMap).find((item) =>
      matchRoute(nextUrl, item.path)
    );

    if (
      (prevRoute?.private !== nextRoute?.private) ||
      (prevRoute?.path !== nextRoute?.path)
    ) {
      if (token) {
        await getProfile(true);
      }

      setLoading(prevRoute?.private !== nextRoute?.private);
    }

    if (nextRoute) {
      if (nextRoute.private) {
        if (!token) {
          await clearStorages();

          if (nextRoute.path !== "/") {
            history.push("/");
          }
        } else {
          if (
            !nextRoute.path.includes("/verify-signup-code") &&
            !nextRoute.path.includes("/account-details") &&
            !nextRoute.path.includes("/about-yourself")
          ) {
            const { data: profile, error: profileError } = await getProfile();
            const {
              data: subscription,
              error: subscriptionError
            } = await getCurrentSubscription();

            if (profileError?.status === 422) {
              await clearStorages();

              history.push("/");
            } else {
              if (profile) {
                if (!profile.attributes.account.activated) {
                  history.push(`/verify-signup-code`);
                } else if (
                  !profile.attributes.account.full_name?.trim()?.length
                ) {
                  history.push("/account-details");
                } else if (!profile.attributes.job_role) {
                  history.push("/about-yourself");
                } else if (
                  !subscription &&
                  !subscriptionError &&
                  !nextRoute.path.includes("/choose-your-plan")
                ) {
                  history.push("/subscriptions");
                }
              } else {
                await clearStorages();

                history.push("/");
              }
            }
          }
        }
      } else {
        if (token) {
          if (
            nextRoute.path !== "/privacy-policy" &&
            nextRoute.path !== "/terms-and-conditions" &&
            nextRoute.path !== "/cookies"
          ) {
            const { error } = await getProfile();

            if (error?.status === 422) {
              await clearStorages();

              history.push("/");
            } else if (nextRoute.path !== "/home") {
              history.push("/home");
            }
          }
        }
      }
    } else {
      if (token) {
        history.push("/home");
      } else {
        history.push("/");
      }
    }

    setLoading(false);
  };

  return (
    loading
      ? <Backdrop style={{ color: "#fff", zIndex: 9999 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      : children
  )
};

// Custom comparison function to prevent unnecessary updates
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.history === nextProps.history
  );
};

const MemoizedAuthenticationProvider = React.memo(
  AuthenticationProvider,
  areEqual
);

export const AuthProvider = withRouter(MemoizedAuthenticationProvider);
