import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: '0px',
    minWidth: '0px',
    maxWidth: '0px',
    height: '100vh',
    background: '#F8FAFC',
    borderLeft: '1px solid #969696',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    zIndex: 10,
    padding: '20px 0px',
    overflow: 'hidden',
    transition: 'all .3s'
  },
  sideBarOpen: {
    width: '270px',
    minWidth: '270px',
    maxWidth: '270px',
    padding: '20px 12px',
  },
  sideBarCloseButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sideBarContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '5px 12px 5px 2px'
  },
  sideBarItem: {
    width: '100%',
    height: '41px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    borderRadius: '8px',
    padding: '12px',
    cursor: 'pointer',
    boxShadow: '-2px 0 0px #D39E26',
    '&:hover': {
      background: '#FFF0CE'
    }
  },
  sideBarItemActive: {
    background: '#FFF0CE',
  },
  newChatButton: {
    background: '#10BE98',
    color: 'white',
    '&:hover': {
      background: '#10BE98',
      color: 'white'
    }
  }
}));
