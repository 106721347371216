import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const myLibraryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getLibraryCampaigns: builder.query<any, any>({
      query: () => ({
        url: `/v1/library/campaigns`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    createLibraryCampaign: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns`,
        method: "post",
        body
      })
    }),
    duplicateLibraryCampaign: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns/duplicate`,
        method: "put",
        body
      })
    }),
    deleteLibraryCampaign: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns`,
        method: "delete",
        body
      })
    }),
    duplicateLibraryEmail: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/emails/duplicate`,
        method: "put",
        body
      })
    }),
    deleteLibraryEmail: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/emails`,
        method: "delete",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getLibraryCampaigns = async (): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.getLibraryCampaigns);
};

export const createLibraryCampaign = async (body): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.createLibraryCampaign, body);
};

export const duplicateLibraryCampaign = async (body): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.duplicateLibraryCampaign, body);
};

export const deleteLibraryCampaign = async (body): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.deleteLibraryCampaign, body);
};

export const duplicateLibraryEmail = async (body): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.duplicateLibraryEmail, body);
};

export const deleteLibraryEmail = async (body): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.deleteLibraryEmail, body);
};
